namespace Entities {

    interface ICustomer {
        firstName: string;
        lastName: string;
    
        companyName: string;

        invoiceAddress: Address;
        shippingAddress: Address;
    }
    export class Customer
        extends BaseEntity {

        firstName: string;
        lastName: string;
        companyName: string;

        invoiceAddress: Address;
        shippingAddress: Address;
        telephoneNumbers: TelephoneNumber[] = [];

        constructor(customer: ICustomer) {
            super();

            this.firstName = customer.firstName;
            this.lastName = customer.lastName;
            this.companyName = customer.companyName;
            this.invoiceAddress = customer.invoiceAddress;
            this.shippingAddress = customer.shippingAddress;
        }

        static cast(obj: any, prototype: any) {
            Object.setPrototypeOf(obj, prototype); 
            for(let telephone of obj.telephoneNumbers) {
                Object.setPrototypeOf(telephone, TelephoneNumber.prototype);
            }
        }
    }
}