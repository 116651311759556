namespace Entities {

    type Themes = "dark" | "light";

    interface ISettingsUser {
        username: string;
        password: string;
    }

    export class Settings
        extends BaseEntity {
        
        _id = "_local/Settings";
        user?: ISettingsUser;
        theme: Themes = "light";
        printerId?: string;
    }
}