namespace Entities {

    interface IGroup {
        parentId: string | null;
        name: LanguageValues;
    }

    export class Group
        extends BaseEntity
        implements IGroup {
        
        _id: string = "";
        _rev: string = "";
        type?: string;
        
        parentId: string | null;
        name: LanguageValues;

        constructor(group: IGroup) {
            super();

            this.parentId = group.parentId;
            this.name = group.name;
        }

        init(){

        }
    }

    export class GroupOrderable
        extends BaseEntity
        implements IGroup, IOrderable {

        parentId: string | null;
        name: LanguageValues;
        quantityPerBunch: number;
        price: number;

        constructor(group: IGroup & IOrderable) {
            super();

            this.parentId = group.parentId;
            this.name = group.name;
            this.quantityPerBunch = group.quantityPerBunch;
            this.price = group.price;
        } 
    }

}