namespace Pages {
    export class CustomersPage {
        private readonly _repository: Repository;

        constructor(repository: Repository) {
            this._repository = repository;
        }
        async index() {
            return new PageViewResult("Customers/", {});
        }
        async add() {
            return new PageViewResult("Customers/add/", {
                model: new Entities.Customer({
                    companyName: "",
                    firstName: "",
                    lastName: "",
                    invoiceAddress: {},
                    shippingAddress: {}
                }),
                invoiceAsShippingAddress: true
            } as Models.CustomerModel);
        }
        async insert({ model }: { model: Models.CustomerModel }) {
            if (model.invoiceAsShippingAddress)
                model.model.shippingAddress = model.model.invoiceAddress;

            await this._repository.put(model.model);
            return new PageActionResult(
                new PageActionBack(new PageActionEvent("customerChanged"))
            );
        }
        async edit({ id }: { id: string }) {
            return new PageViewResult("Customers/edit/", {
                model: await this._repository.get<Entities.Customer>(id, Entities.Customer),
                invoiceAsShippingAddress: false
            } as Models.CustomerModel);
        }

        async update({ id, model }: { id: string, model: Models.CustomerModel }) {
            if (model.invoiceAsShippingAddress)
                model.model.shippingAddress = model.model.invoiceAddress;

            await this._repository.put(model.model);
            return new PageActionResult(
                new PageActionBack(new PageActionEvent("customerChanged"))
            );
        }

        async delete({ id, model }: {id: string, model: Models.CustomerModel}) {
            await this._repository.delete(model.model);
            return new PageActionResult(
                new PageActionBack(new PageActionEvent("customerChanged"))
            );
        }

    }
}