class SiteElementSearch
    extends AbstractSiteElement {
    private _query: SiteHelperQuery;

    constructor(element: HTMLElement, sitePage: SitePage) {
        super(element, sitePage);
        
        let input = this.getAttr("data-bind")
            ?? throwError("Unable to get bind");
        
        let inputElement = sitePage.getElementById(input)
            ?? throwError("Unable to find element");
        
        let repository = sitePage.getRepository();
        let queryName = this.getAttr("data-query") ?? throwError("No data-query given.");
        let queryInfo = SiteHelperQuery.getQuery(queryName, repository);


        let query = this.query(".results").create<SiteHelperQuery, HTMLElement>(
            a => new SiteHelperQuery(a, queryName, repository)) ?? throwError("Unable to find query");

        query.clickEvent.on(async a => {
            let queryFunction = this.getAttr("data-function");
            if (queryFunction == "order") {
                this.trigger("ee.function", true, {
                    function: queryFunction,
                    model: a.model
                });
            } else if (queryFunction == "page") {
                this.trigger("ee.function", true, {
                    function: queryFunction,
                    page: this.getAttr("data-function-page"),
                    model: a.model
                });
            }
        });

        this._query = query;

        var refreshEvents = this.getAttr("data-refresh");
        if (refreshEvents) {
            var refreshEventsArray = refreshEvents.split(",");
            Site.getSite().globalEvent.on(async event => {
                if (refreshEventsArray.includes(event))
                    await this._query.refresh();
            })
        }
        
        sitePage.on("ee.resize", (e: CustomEvent) => {
            this._query.resize();
        })

        inputElement.on("change", async (e: CustomEvent) => {
            this._query?.setFilter({
                input: e.detail
            })      
        });
    }

    setValue(value: any) {

    }
    getValue() {

    }
}