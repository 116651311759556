class Login 
    extends QueryElement<HTMLElement> {

    private readonly _translate: Translate;
    private readonly _username: IQueryElement;
    private readonly _password: IQueryElement;
    private readonly _repository: Repository;
    private readonly _login: IQueryElement;
    private readonly _text: IQueryElement;
    private readonly _error: IQueryElement;
    private readonly _keyboard: SiteKeyboard;

    constructor(element: HTMLElement) {
        super(element);
        
        document.documentElement.classList.add("light");
        this._translate = new Translate("nl");
        this._repository = new Repository();

        var keyboard = new Query("#keyboard").create<SiteKeyboard, HTMLElement>(a => new SiteKeyboard(a))
            ?? throwError("Keyboard element not found");

        var submit = this.query('button[type="submit"]').first() 
            ?? throwError("Submit element not found");
        
        this._username = this.query('input[name="username"]').first() 
            ?? throwError("Username element not found");
        this._password = this.query('input[name="password"]').first() 
            ?? throwError("Password element not found");
        this._login = this.query(".login").first()
            ?? throwError("Login element not found");
        this._text = this.query(".text").first()
            ?? throwError("Text element not found");
        this._error = this.query(".login .error").first()
            ?? throwError("Error element not found");
        
        this._keyboard = keyboard;
        
        // Add events
        this._username.on("keydown", async (e: KeyboardEvent) => {
            if (e.code === "Enter")
                this._password.focus();
        });
        this._password.on("keydown", async (e: KeyboardEvent) => {
            if (e.code === "Enter")
                await this.login();
        })
        submit.on("click", async () => { 
            await this.login(); 
        });
    }

    async init() {
        await this._translate.init();
        await this._keyboard.setDefaultLayout("us");
        Translator.translate(this, this._translate);

        var settings = await this._repository.getSettings();
        if (settings !== undefined && settings.user !== undefined) {
            this.doLogin(settings.user.username, settings.user.password, true);
        } else {
            this.showLogin();
        }
    }

    showLogin() {
        this.addClass("show-login");
    }
    showMessage(message: string) {
        this.removeClass("show-login");
        this._text.setHtml(`<h1>${message}</h1>`);
    }
    showError(message: string) {
        this._error.addClass("show");
        this._error.setHtml(message);
    }

    async login() {
        var username = this._username.getVal() || "";
        var password = this._password.getVal() || "";

        await this.doLogin(username, password, false);
    }

    private async doLogin(username: string, password: string, autoLogin: boolean) {
        this.showMessage(this._translate.translate("_LOGGING_IN"));

        if (await this._repository.upgrade(username, password)) {
            // Do a sync
            //this.showMessage(this._translate.translate("_COMPACTING_OFFLINE"));
            //await this._repository.compactOffline();
            //this.showMessage(this._translate.translate("_COMPACTING_ONLINE"));
            //await this._repository.compactOnline();
            this.showMessage(this._translate.translate("_SYNCHRONIZING"));
            await this._repository.sync();

            // Save authentication
            if (!autoLogin) {
                var settings = new Entities.Settings();
                settings.user = {
                    username: username,
                    password: password
                }
                await this._repository.putSettings(settings);
            }
            
            // Handover to site
            var site = document.getElementById("site")
                ?? throwError("Site not found!");
            
            new Site(site, this._translate, this._repository, this._keyboard);
            
            // Hide myself
            this.addClass("hide");
        } else {
            this.showLogin();
            this.showError(this._translate.translate("_LOGIN_ERROR"));
        }
    }
}