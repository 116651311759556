namespace Pages {
    export class UsersPage {
        private readonly _repository: Repository;

        constructor(repository: Repository) {
            this._repository = repository;
        }
        async index() {
            return new PageViewResult("Users/", {});
        }
        async add() {
            return new PageViewResult("Users/add/", new Entities.User({
                username: "",
                firstName: "",
                lastName: "",
                password: ""
            }));
        }
        async insert({ model }: { model: Entities.User }) {

            model.setPassword(model.password);
            await this._repository.put(model);

            return new PageActionResult(
                new PageActionBack(new PageActionEvent("userChanged"))
            );
        }
        async edit({ id }: { id: string }) {
            const user = await this._repository.get<Entities.User>(id, Entities.User);
            user.password = "";

            return new PageViewResult("Users/edit/", 
                user
            );
        }

        async update({ id, model }: { id: string, model: Entities.User}) {
            
            if (model.password == "") {
                const user = await this._repository.get<Entities.User>(id, Entities.User);
                model.password = user.password;
            } else {
                model.setPassword(model.password);
            }
            await this._repository.put(model);

            return new PageActionResult(
                new PageActionBack(new PageActionEvent("userChanged"))
            );
        }

        async delete({ id, model }: {id: string, model: Entities.User}) {
            await this._repository.delete(model);
            return new PageActionResult(
                new PageActionBack(new PageActionEvent("userChanged"))
            );
        }

    }
}