namespace Pages {
    export class HomePage {
        private readonly _repository: Repository;

        constructor(repository: Repository) {
            this._repository = repository;
        }
        async index() {
            return new PageViewResult("Home/", {});
        }

    }
}