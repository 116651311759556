/// <reference path="../../../node_modules/@types/pouchdb/index.d.ts" />
/// <reference path="../definitions/QueryTs.d.ts" />
/// <reference path="../definitions/QueryTs.Template.d.ts" />
/// <reference path="../definitions/QueryTs.SVG.d.ts" />
/// <reference path="../definitions/QueryTs.Touch.d.ts" />
/// <reference path="../definitions/QueryTs.Upload.d.ts" />

interface ISiteFunction {
    function: string;
    model: SiteHelperQueryRequestItem;
}
interface ISiteFunctionPage 
    extends ISiteFunction {
    page: string;
}

class Site 
    extends QueryElement<HTMLElement> {
    
    private readonly _repository: Repository;
    private readonly _translate: Translate;
    private readonly _pages: SitePages;
    private readonly _dialog: SiteDialog;
    private readonly _keyboard: SiteKeyboard;
    private _order?: SiteOrder;


    private _globalEvent: QueryEventAsync<string>;
    get globalEvent(){ return this._globalEvent.expose(); } 

    constructor(element: HTMLElement, translate: Translate, repository: Repository, keyboard: SiteKeyboard) {
        super(element);

        this._repository = repository;
        this._translate = translate;
        this._keyboard = keyboard;
        this._globalEvent = new QueryEventAsync<string>();

        this._pages = this.query("#pages").create<SitePages, HTMLElement>(a => new SitePages(a, {
            hide: () => this.hidePages(),
            show: () => this.showPages()
        }, this)) ?? throwError("Pages not found.");
        
        this._dialog = this.query("#dialog").create<SiteDialog, HTMLElement>(a => new SiteDialog(a, this))
            ?? throwError("Dialog not found.");
        
        window.onresize = () => this.resize();

        this.on("ee.function", async (e:CustomEvent) => {
            let data : ISiteFunction = e.detail;
            switch(data.function) {
                case "order":
                    if (this._order != null)
                        await this._order.addOrder(data.model.getResult())
                    //await data.model.refresh();
                    break;
                case "page":
                    let dataPage = data as ISiteFunctionPage;
                    let id = data.model.getResult()._id;
                    this._pages.setLocation("Open", dataPage.page + id, false);
                    break;
            }
        });
        this.on("ee.event", async (e:CustomEvent) => {
            await this._globalEvent.trigger(e.detail);
        });
        
        this.init();
        //this.test();
        /*
        this.query("#sync").first()?.on("click", async () => {
            await this._repository.sync();
            this.test();
            console.log("Synced database!");
        });*/
    }
    resize() {
        this._pages.resize();
    }
    async init() {
        var settings = await this._repository.getSettings()
            ?? throwError("Should have settings here, but haven't.");
        
        // Add indexes
        await this._repository.createIndex<Entities.Order, Indexes.Order>(new Indexes.Order());
        await this._repository.createIndex<Entities.Purchase, Indexes.Purchase>(new Indexes.Purchase());
        await this._repository.createIndex<Entities.Customer, Indexes.Customer>(new Indexes.Customer());
        await this._repository.createIndex<Entities.User, Indexes.User>(new Indexes.User());
        await this._repository.createIndex<Entities.Printer, Indexes.Printer>(new Indexes.Printer());

        await this.changeSettings(settings);

        //await this.load();

        await this.login("User/5fc20eb5-3958-41a3-926c-b63466039b43");

        this.removeClass("hide");
    }
    getDialog() {
        return this._dialog;
    }
    async login(userId: string) {
        this._order = this.query("#order").create<SiteOrder, HTMLElement>(a => new SiteOrder(a, this, this._pages, userId))
            ?? throwError("Order not found.");
        await this._order.init();
    }
    async orderPause() {
        await this._order?.pause();
    }
    async orderResume(id: string) {
        await this._order?.resume(id);
    }
    async changeSettings(settings: Entities.Settings) {
        // Set theme
        var root = document.documentElement;
        root.className = "";
        switch(settings.theme) {
            case "dark":
                root.classList.add("dark");
                break;
            default:
            case "light":
                root.classList.add("light");
                break;
        }
        // Set keyboard

        await this._repository.putSettings(settings);
    }
    showPages() {
        this.addClass("pages");
    }
    hidePages() {
        this.removeClass("pages");
    }
    async load() {
        // Delete old purcahses
        var query = this._repository.query<Entities.Purchase, Indexes.Purchase, new () => Indexes.Purchase>(Indexes.Purchase);
        var purchases = await query.toArray();
        for(var purchase of purchases.docs.filter(a => a.type == "Purchase")) {
            await this._repository.delete(purchase);
            console.log(`Deleting: ${purchase._id}/${purchase.name["nl"]}`);
        }


        // Load new purchases
        var request = new QueryRequest("products.json");
        var answer = await request.request();
        var objects = await answer.json() as Entities.Purchase[];
        for(var object of objects) {
            Entities.Purchase.cast(object, Entities.Purchase.prototype);
            await this._repository.put(object);
            console.log(`Deleting: ${object._id}/${object.name["nl"]}`);
        }
    }
    async test() {
        const dutchLastNames = [
            "De Jong", "Jansen", "De Vries", "Van den Berg", "Van Dijk", "Bakker", "Janssen",
            "Visser", "Smit", "Meijer", "De Boer", "Mulder", "De Groot", "Bos", "Vos", "Peters",
            "Hendriks", "Van Leeuwen", "Dekker", "Brouwer", "Van der Linden", "Dijkstra", "Smits",
            "De Graaf", "Van der Meer", "Kok", "Jacobs", "Van der Heijden", "Schouten", "Van Beek"
        ];
        const dutchFirstNames = [
            "Daan", "Noah", "Sem", "Lucas", "Jesse", "Finn", "Levi", "Thomas", "Milan", "Bram",
            "Luuk", "Julian", "Tim", "Thijs", "Sam", "Ruben", "Lars", "Mees", "Niels", "Benjamin",
            "Emma", "Tess", "Sophie", "Julia", "Anna", "Mila", "Sara", "Eva", "Zoë", "Evi"
        ];
        const bedrijfsnamen = [
            "HelderZicht Innovaties",
            "ToekomstBouwers",
            "GroenEnergie Oplossingen",
            "QuantumSprong Technologie",
            "BlauwTop Software",
            "GroenVeld Energie",
            "NexusDynamiek",
            "KristalKlaar Water",
            "OneindigNetwerken",
            "ZonneVlam Hernieuwbare Energie",
            "Digitale Horizon",
            "TerraVast Tuinarchitectuur",
            "HemelHoog Bouw",
            "OceaanZicht Reizen",
            "StadsRand Ontwikkelingen",
            "WolkenNegen Analytica",
            "Gouden Graan Handel",
            "ZuiverStroom Filtratie",
            "Voorwaarts Motoren",
            "Visionaire Ondernemingen"
        ];
        for (let i = 0; i < 500; i++) {
            const companyName = bedrijfsnamen[Math.floor(Math.random() * bedrijfsnamen.length)] + " B.V.";
            const firstName = dutchFirstNames[Math.floor(Math.random() * dutchFirstNames.length)];
            const lastName = dutchLastNames[Math.floor(Math.random() * dutchLastNames.length)];

            await this._repository.put(new Entities.Customer({
                companyName: companyName,
                firstName: firstName,
                lastName: lastName,
                shippingAddress: {
                    city: "Rijnsburg",
                    country: "Duitsland",
                    line1: "Inkoperhof 8",
                    line2: "",
                    state: "Zuid-Holland",
                    zipCode: "2231HC"
                },
                invoiceAddress: {
                    city: "Rijnsburg",
                    country: "Duitsland",
                    line1: "Inkoperhof 8",
                    line2: "",
                    state: "Zuid-Holland",
                    zipCode: "2231HC"
                }
            }));
        }
        
        this._repository.viewCleanup();
    }
    getKeyboard() {
        return this._keyboard;
    }
    getTranslate() {
        return this._translate;
    }
    getRepository() {
        return this._repository;
    }
    static getSite() {
        let content = document.getElementById("site");
        if (!content)
            throw "Couldn't find Site element";
        
        return QueryElement.get<Site>(content);
    }
    static getPage() {
        let site = Site.getSite();
        return site._pages;
    }
    static getOrder() {
        let site = Site.getSite();
        return site._order;
    }
    static translate(input: LanguageValues) {
        let site = Site.getSite();
        let language = site.getTranslate().getLanguage();

        return input[language] ?? input[Object.keys(input)[0] as Languages] ?? ""
    }
}

function throwError(message: string): never {
    throw message;
}