class SiteDialog
    extends QueryElement<HTMLElement> {
    private readonly _site: Site;
    private readonly _pages: SitePages;

    constructor(element: HTMLElement, site: Site) {
        super(element);

        this._site = site;
        this._pages = this.query(".pages").create<SitePages, HTMLElement>(a => new SitePages(a, {
            show: () => this.show(),
            hide: () => this.hide()
        }, site)) ?? throwError("Pages not found.");

        this.start();
    }

    start() {
        var home = this._pages.getPage("Home/");

        if (home == null)
            return;
        
        this._pages.setLocation("", "Home/", true, {});
        this._pages.makeActive(home, { page: "Home", view: "" }, {});
    }
    stop() {
        this._pages.hide();
    }

    show() {
        this.removeClass("hide");
    }
    hide() {
        this.addClass("hide");
    }

}