type Languages = "en" | "nl";
type LanguageValues = {
    [key in Languages]?: string;
};
type Translations = {
    [key: string]: string;
};



class Translate {

    private readonly _language: Languages;
    private _translations: Translations = {};

    constructor(language: Languages) {
        this._language = language;
    }
    async init() {
        var languages = new QueryRequest(`./dest/languages/${this._language}.json`);
        var request = await languages.request();
        this._translations = (await request.json()) as Translations;
    }

    translate(input: string) {
        return this._translations[input];
    }

    getLanguage() {
        return this._language;
    }
}