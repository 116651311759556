namespace Queries {
    interface ICustomerQueryFilter
        extends IQueryFilter {

    }

    interface IPurchaseOrdered {
        ordered?: number;
    }
    export class CustomerQuery
        implements ITQueryInfo<Indexes.Customer, Entities.Customer, ICustomerQueryFilter, Entities.Customer> {
        
        private readonly _query: RepositoryQuery<Entities.Customer, Indexes.Customer>;

        constructor(repository: Repository) {
            this._query = repository.query<Entities.Customer, Indexes.Customer, new () => Indexes.Customer>(Indexes.Customer);
        }

        async execute(context: ITQueryRequestContext<Indexes.Customer, Entities.Customer>) {

            let query = this._query;
            if (context.filter.input != null)
                query = query.whereContainsAll("byQuery", context.filter.input.split(" ").filter(Boolean));

            var result  = await query
                .orderBy(context.orderBy ?? "byLastName")
                .skip(context.from)
                .limit(context.limit)
                .toArray();

            return {
                docs: result.docs,
                totalResults: result.totalResults
            }
        }
        
    }
}