namespace Indexes {
    export class Customer
        implements IBaseIndex<Entities.Customer> {
        
        _id = "Customer";
        views = {
            byLastName: {
                map: function (doc: Entities.Customer) {
                    if (doc.type === 'Customer') {
                        emit(`${doc.lastName}|${doc.firstName}`);
                    }
                }
            },
            byQuery: {
                map: function (doc: Entities.Customer) {
                    if (doc.type === 'Customer') {
                        for(var lastNamePart of doc.lastName.split(" ")) {
                            emit(lastNamePart.toLowerCase());
                        }
                        for(var firstNamePart of doc.firstName.split(" ")) {
                            emit(firstNamePart.toLowerCase());
                        }
                        for(var companyNamePart of doc.companyName.split(" ")) {
                            emit(companyNamePart.toLowerCase());
                        }
                    }
                }
            },
            byCountry: {
                map: function (doc: Entities.Customer) {
                    if (doc.type === 'Customer') {
                        if (doc.shippingAddress?.country)
                            emit(doc.shippingAddress.country)
                        if (doc.invoiceAddress?.country)
                            emit(doc.invoiceAddress.country)
                    }
                }
            }
        }
    }
}