namespace Entities {

    interface ITelephoneNumber {
        number: string;
        category: string;
    }
    export class TelephoneNumber {
        number: string;
        category: string;

        constructor(number: ITelephoneNumber) {
            this.number = number.number;
            this.category = number.category;
        }
    }
}