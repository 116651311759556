class Hashing {

    static async sha256(message:string) {
        const msgBuffer = new TextEncoder().encode(message);
        const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer);
        return Hashing.toHex(new Uint8Array(hashBuffer));
    }

    static generateSalt(length:number) {
        const array = new Uint8Array(length);
        crypto.getRandomValues(array);
        return Hashing.toHex(array);
    }

    private static toHex(buffer:Uint8Array) {
        return Array.from(buffer, byte => byte.toString(16).padStart(2, '0')).join('');
    }

}