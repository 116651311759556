class SiteElementText
    extends AbstractSiteElement {

    private _listener?: (e: CustomEvent) => any;
    private _input: SiteHelperText;

    constructor(element: HTMLElement, sitePage: SitePage) {
        super(element, sitePage);

        var icon = this.getAttr("data-icon");
        var label = this.getAttr("data-label");

        if (icon != null)
            QueryElement.QueryElement("i")
                .setText(icon)
                .appendTo(this);
        
        if (label != null)
            QueryElement.QueryElement("label")
                .setAttr("translate", "")
                .setHtml(label)
                .appendTo(this);
        
        this._input = new SiteHelperText(sitePage.getKeyboard()).appendTo(this);

        this._input.on("change", () => {
            this.trigger("change", false, this.getValue());
        });
    }

    setValue(value: any) {
        this._input.setValue(value);
    }
    getValue() {
        return this._input.getValue();
    }
}