class SiteHelperText
    extends QueryElement<HTMLElement> {

    private readonly _keyboard: SiteKeyboard;
    private _listener?: (e: CustomEvent) => any;
    private _activeKey: QueryElement<HTMLElement>;

    constructor(siteKeyboard: SiteKeyboard) {
        super(document.createElement("DIV"));

        this._keyboard = siteKeyboard;

        this.setAttr("tabindex", "0");
        this.addClass("input");
        
        this._activeKey = QueryElement.QueryElement("span", true)
            .setHtml("&nbsp;")
            .addClass("active")
            .appendTo(this);
        
        this.on("focus", () => this.activated());
        this.on("blur", () => this.deactivated());
    }

    async keyPressed(key: string) {
        // Handle special keys
        switch(key as SpecialKeys) {
            case "KeyBackspace":
                var prev = this._activeKey.prev();
                if(prev == null)
                    return;
                await prev.remove();
                break;
            case "KeySpacebar":
                key = " ";
                break;
            case "KeyEnter":
                return;
            case "KeyNumbers":
            case "KeyShift":
                return;
        }

        // Add text
        switch(key as SpecialKeys) {
            case "KeyBackspace":
                break;
            default:
            case "KeySpacebar":
                QueryElement.QueryElement("span")
                    .setText(key)
                    .appendBefore(this._activeKey);
                break;
        }

        this.trigger("change", false, this.getValue());
    }

    setValue(value: string) {
        // Remove current
        for (let child of this.children()) {
            if (child != this._activeKey)
                child.remove();
        }
        // Add new
        for (let i = 0; i < value.length; i++) {
            QueryElement.QueryElement("span")
                .setText(value[i])
                .appendBefore(this._activeKey);
        }
    }

    getValue() {
        return this.children().map(a => a.getText()).join('').slice(0, -1);
    }

    activated() {
        this.addClass("active");
        this._keyboard.show();
        this._listener = this._keyboard.on("keyPress", (e: CustomEvent) => {
            var keyPressed = e.detail as string;
            this.keyPressed(keyPressed);
        });
    }
    deactivated() {
        this.removeClass("active");
        if (this._listener != null)
            this._keyboard.off("keyPress", this._listener);
        this._keyboard.hide();
    }
}