class SiteElementCheckbox
    extends AbstractSiteElement {

    private readonly _input: QueryElement<HTMLElement>;

    constructor(element: HTMLElement, sitePage: SitePage) {
        super(element, sitePage);

        var label = this.getAttr("data-label");
        
        this._input = QueryElement.QueryElement("i")
            .addClass("checkbox")
            .setHtml("check_box_outline_blank")
            .setAttr("tabindex", "0")
            .appendTo(this);

        if (label != null)
            QueryElement.QueryElement("label")
                .setAttr("translate", "")
                .setHtml(label)
                .appendTo(this);
        
        this.on("click", () => this.clicked());
    }

    clicked() {
        if (this.getValue() == true) {
            this._input.setHtml("check_box_outline_blank");
        } else {
            this._input.setHtml("select_check_box");
        }
    }

    setValue(value: any) {
        if (value != this.getValue())
            this.clicked();
    }

    getValue() {
        return this._input.getHtml() == "select_check_box"
    }
}