namespace Pages {
    export class OrdersPage {
        private readonly _repository: Repository;

        constructor(repository: Repository) {
            this._repository = repository;
        }
        async index() {
            return new PageViewResult("Orders/", {});
        }
        async pause() {
            await Site.getSite().orderPause();
            return new PageActionResult(new PageActionEvent("synced"));
        }
        async resume({ id }: { id: string }) {
            await Site.getSite().orderResume(id);
            return new PageActionResult(new PageActionEvent("synced"));
        }
        async refresh() {
            await this._repository.sync();
            return new PageActionResult(new PageActionEvent("synced"));
        }
        async paused() {
            return new PageViewResult("Orders/paused", {});
        }

    }
}