namespace Queries {
    interface IOrderQueryFilter
        extends IQueryFilter {
        
        closed?: boolean;
    }

    interface IOrderResult {

    }

    export class OrderQuery
        implements ITQueryInfo<Indexes.Order, Entities.Order, IOrderQueryFilter, IOrderResult> {
        
        private readonly _query: RepositoryQuery<Entities.Order, Indexes.Order>;
        private readonly _repository: Repository;

        constructor(repository: Repository) {
            this._query = repository.query<Entities.Order, Indexes.Order, new () => Indexes.Order>(Indexes.Order);
            this._repository = repository;
        }

        async execute(context: ITQueryFilterRequestContext<Indexes.Order, Entities.Order, IOrderQueryFilter>) {

            let query = this._query;
            if (context.filter.closed != null)
                query = query.whereEquals("byClosed", context.filter.closed);

            var result  = await query
                .orderBy(context.orderBy ?? "byDateTimeStart", true)
                .skip(context.from)
                .limit(context.limit)
                .toArray();
            
            var users = await this._repository.getArray<Entities.User>(result.docs.map(a => a.userId).distinct(), Entities.User);

            var docs = result.docs.map(doc => ({
                ...doc,
                username: users[doc.userId]?.username
            }));

            return {
                docs: docs,
                totalResults: result.totalResults
            }
        }
        
    }
}