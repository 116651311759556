namespace Indexes {
    export interface IBaseIndex<T extends Entities.BaseEntity> {
        _id: string;
        views: {
            [viewName: string]: {
                map: (doc: T) => void;
                reduce?: (doc: T) => void;
            };
        };
    }
    export interface IPutBaseIndex {
        _id: string;
        views: { [key: string]: { map: string, reduce?: string } };
    }
}
function emit(input: any) {
        
}