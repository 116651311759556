enum SiteOrderFunctions { 
    Quantity='func_quantity', 
    Reduction='func_reduction', 
    Price='func_price' 
};
enum SiteOrderSpecialKeys { 
    Negative='special_negative', 
    Decimal='special_decimal', 
    Backspace='special_backspace' 
};

type SiteOrderNumPad = SiteOrderFunctions | SiteOrderSpecialKeys | number;

interface ISiteOrderNumPadButton {
    key: string,
    func: SiteOrderNumPad
}


class SiteOrder
    extends QueryElement<HTMLElement> {
    
    private readonly _orderElement: SiteOrderOrder;
    private readonly _totalElement: SiteOrderTotal;
    private readonly _numPadElement: IQueryElement;

    private _function: SiteOrderFunctions = SiteOrderFunctions.Quantity;
    private _functionAppend: boolean = false;
    private _functionAppendTimer?: number;
    private _functionAppendString: string = "0";

    private _numPad: ISiteOrderNumPadButton[] = [
        { key: "1", func: 1}, { key: "2", func: 2}, { key: "3", func: 3}, { key: "#", func: SiteOrderFunctions.Quantity}, 
        { key: "4", func: 4}, { key: "5", func: 5}, { key: "6", func: 6}, { key: "%", func: SiteOrderFunctions.Reduction}, 
        { key: "7", func: 7}, { key: "8", func: 8}, { key: "9", func: 9}, { key: "€", func: SiteOrderFunctions.Price}, 
        { key: "+/-", func: SiteOrderSpecialKeys.Negative}, { key: "0", func: 0}, { key: ",", func: SiteOrderSpecialKeys.Decimal}, { key: "<i>backspace</i>", func: SiteOrderSpecialKeys.Backspace}, 
    ];

    constructor(element: HTMLElement, site: Site, sitePages: SitePages, userId: string) {
        super(element);

        Translator.translate(this, site.getTranslate());
        this.query('button[data-href]').createAll<SiteButtonNavigate, HTMLElement>(a => new SiteButtonNavigate(a, sitePages));
        
        this._orderElement = this.query('.lines').create<SiteOrderOrder, HTMLElement>(a => new SiteOrderOrder(a, sitePages.getRepository(), userId))
            ?? throwError("Unable to find orderElement.");
        this._totalElement = this.query('.total').create<SiteOrderTotal, HTMLElement>(a => new SiteOrderTotal(a))
            ?? throwError("Unable to find totalElement.");
        this._numPadElement = this.query('.numpad').first() 
            ?? throwError("Unable to find numpad");

        for(var button of this._numPad) {
            this._numPadElement.append(new SiteOrderNumPadButton(this, button.key, button.func));
        }

        this.setFunction(SiteOrderFunctions.Quantity);

        this._orderElement.activeChangedEvent.on(async a => {
            this._stopAppend();
        })

        this.on("keyPress", (e: CustomEvent) => {
            // ASYNC
            this._handleNumPad(e.detail);
        })
    }

    async init() {
        await this._orderElement.init();
        this._totalElement.redraw(this.getOrder());
    }

    async pause() {
        await this._orderElement.pause();
        this._totalElement.redraw(this.getOrder());
    }
    async resume(id: string) {
        await this._orderElement.resume(id);
        this._totalElement.redraw(this.getOrder());
    }

    private _isFunction(input: SiteOrderNumPad): input is SiteOrderFunctions {
        return Object.values(SiteOrderFunctions).includes(input as SiteOrderFunctions);
    }
    private _isSpecialKey(input: SiteOrderNumPad): input is SiteOrderSpecialKeys {
        return Object.values(SiteOrderSpecialKeys).includes(input as SiteOrderSpecialKeys);
    }

    private async _handleNumPad(func: SiteOrderNumPad) {
        if (this._isFunction(func)) {
            this.setFunction(func);
            this._stopAppend();
        } else if (this._isSpecialKey(func)) {
            switch(func) {
                case SiteOrderSpecialKeys.Backspace:
                    this._functionAppendString = this._removeLastChar(this._functionAppendString);
                    break;
                case SiteOrderSpecialKeys.Decimal:
                    this._functionAppendString += ".";
                    break;
                case SiteOrderSpecialKeys.Negative:
                    if (this._functionAppendString.startsWith("-"))
                        this._functionAppendString = this._functionAppendString.substring(1);
                    else
                        this._functionAppendString = "-" + this._functionAppendString;
                    break;
            }
            await this._setNumber();
            this._extendAppend();
        } else {
            this._functionAppendString += func.toString();
            await this._setNumber();
            this._extendAppend();
        }

    }

    private _removeLastChar(str: string) {
        return str.length > 1 ? str.substring(0, str.length - 1) : str;
    }

    private _extendAppend() {
        this._functionAppend = true;

        if (this._functionAppendTimer != null)
            window.clearTimeout(this._functionAppendTimer);

        this._functionAppendTimer = window.setTimeout(() => { this._stopAppend() }, 10 * 1000);
    }
    private _stopAppend() {
        this._functionAppend = false;
        this._functionAppendString = "0";
    }
    private async _setNumber() {
        let activeLine = this._orderElement.getActive();
        if (activeLine == null)
            return;

        var current = Number(this._functionAppendString);

        switch(this._function) {
            case SiteOrderFunctions.Price:
                await activeLine.setPrice(current);
                break;
            case SiteOrderFunctions.Quantity:
                await activeLine.setQuantity(current);
                break;
            case SiteOrderFunctions.Reduction:
                await activeLine.setReduction(current);
                break;
        }
        
        this.redraw(activeLine.getLine());
    }

    setFunction(func: SiteOrderFunctions) {
        for (var element of this._numPadElement.children<SiteOrderNumPadButton>()) {
            var elementFunc = element.getFunction();
            
            if (this._isFunction(elementFunc)) {
                if (element.getFunction() == this._function)
                    element.makeInactive();
                
                if (element.getFunction() == func)
                    element.makeActive();
            }
        }

        this._function = func;
    }

    async addOrder(orderable: Entities.IOrderable & Entities.BaseEntity) {
        var line = await this._orderElement.addLine(orderable);
        this.redraw(line);
    }
    getOrder() {
        return this._orderElement.getOrder();
    }
    getLine(orderable: Entities.IOrderable & Entities.BaseEntity) {
        return this._orderElement.getLine(orderable);
    }

    redraw(line: Entities.OrderLine) {
        this._orderElement.redraw(line);
        this._totalElement.redraw(this.getOrder());
    }
}

class SiteOrderNumPadButton
    extends QueryElement<HTMLElement> { 

    private _function: SiteOrderNumPad;
    
    constructor(order: SiteOrder, text: string, func: SiteOrderNumPad) {
        super(document.createElement("button"))

        this.setHtml(text);
        this._function = func;

        this.on("click", e => {
            order.trigger("keyPress", false, this._function);
        });
    }

    getFunction() {
        return this._function;
    }

    makeActive() {
        this.addClass("active");
    }
    makeInactive() {
        this.removeClass("active");
    }
}

class SiteOrderOrder
    extends QueryElement<HTMLElement> {

    private _order: Entities.Order;
    private _repository: Repository;
    private _active?: SiteOrderOrderItem;

    private _activeChangedEvent: QueryEventAsync<SiteOrderOrderItem> = new QueryEventAsync<SiteOrderOrderItem>();
    get activeChangedEvent(){ return this._activeChangedEvent.expose(); } 

    constructor(element: HTMLElement, repository: Repository, userId: string) {
        super(element);

        this._order = new Entities.Order(userId);
        this._repository = repository;
    }

    async init() {
        this.clear();

        var current = await Stores.CurrentStore.get(this._repository);
        if (current.order != null) {
            // Continue to order
            this._order = current.order;
            for(var line of this._order.orderLines) {
                new SiteOrderOrderItem(line, this).appendTo(this);
            }
        } else {
            // Save current
            current.order = this._order;
            await Stores.CurrentStore.set(this._repository);
        }
    }

    async pause() {
        await this._repository.put(this._order);
        await this._repository.sync();

        var userId = this._order.userId;

        var current = await Stores.CurrentStore.get(this._repository);
        current.order = this._order = new Entities.Order(userId);

        await this.init();
        await this.commit();
    }
    async resume(id: string) {
        var order = await this._repository.get<Entities.Order>(id, Entities.Order);
        await this._repository.delete(order);
        await this._repository.sync();

        var current = await Stores.CurrentStore.get(this._repository);

        order._id = "";
        order._rev = "";
        order.userId = this._order.userId;
        current.order = this._order = order;

        await this.init();
        await this.commit();
    }

    getOrder() {
        return this._order;
    }
    async addLine(orderable: Entities.IOrderable & Entities.BaseEntity) {
        var line = this._order.getLine(orderable);
        if (line == null) {
            line = new Entities.OrderLine(orderable, 1);
            this._order.orderLines.push(line);

            var item = new SiteOrderOrderItem(line, this)
                .appendTo(this);
            
            this.makeActive(item);
        } else {
            line.quantity += 1;

            this.findOrderItem(line)
                .forEach(a => this.makeActive(a));
        }

        await this.commit();
        return line;
    }
    getLine(orderable: Entities.IOrderable & Entities.BaseEntity) {
        return this._order.getLine(orderable);
    }
    findOrderItem(orderLine: Entities.OrderLine) {
        return this.children<SiteOrderOrderItem>()
            .filter(a => a.getLine() == orderLine);
    }
    async removeLine(orderLine: Entities.OrderLine) {
        this.findOrderItem(orderLine)
            .forEach(a => a.remove());
        
        const index = this._order.orderLines.findIndex(item => item == orderLine);
        if (index !== -1) {
            this._order.orderLines.splice(index, 1);
        }

        await this.commit();
    }
    async commit() {
        await Stores.CurrentStore.set(this._repository);
        this.trigger("ee.event", true, "orderChanged");
    }

    redraw(orderLine: Entities.OrderLine) {
        for (var item of this.children<SiteOrderOrderItem>()) {
            if (item.getLine() == orderLine) {
                item.redraw(orderLine);
            }
        }
    }

    makeActive(orderItem: SiteOrderOrderItem) {
        if (this._active != null)
            this._active.makeInactive();
        
        this._active = orderItem;
        this._active.makeActive();

        this._active.get()?.scrollIntoView({ behavior: "smooth" });

        this._activeChangedEvent.trigger(orderItem);
    }

    getActive() {
        return this._active;
    }
}

class SiteOrderOrderItem
    extends QueryElement<HTMLElement> {
    
    private readonly _line: Entities.OrderLine;
    private readonly _parent: SiteOrderOrder;

    constructor(line: Entities.OrderLine, parent: SiteOrderOrder) {
        super(document.createElement("DL"));

        this._parent = parent;
        this._line = line;

        this.draw(line);

        this.on("click", () => {
            parent.makeActive(this);
        });
    }

    draw(line: Entities.OrderLine) {
        // Title
        var price = QueryElement.QueryElement("price");
        price.addClass("price");
        price.setText(`€ ${line.totalPrice().toFixed(2)}`);

        var title = QueryElement.QueryElement("dt");
        title.setText(line.name);
        title.append(price);
        
        // Quantity
        var quantity = QueryElement.QueryElement("dd");
        quantity.setHtml(`<b>${line.quantity.toFixed(3)}</b> Stuk(s) bij € ${line.price.toFixed(2)} / stuk`);

        // User
        var user = QueryElement.QueryElement("dd");
        user.addClass("small");
        user.setText("Gebruiker");

        this.append(title);
        this.append(quantity);
        this.append(user);
    }

    redraw(line: Entities.OrderLine) {
        this.children().forEach(a => a.remove());
        this.draw(line);
    }

    getQuantity() {
        return this._line.quantity;
    }
    getPrice() {
        return this._line.price;
    }
    getReduction() {
        return this._line.reduction;
    }
    getLine() {
        return this._line;
    }

    async setQuantity(quantity: number) {
        this._line.quantity = quantity;
        await this._parent.commit();
    }
    async setPrice(price: number) {
        this._line.price = price;
        await this._parent.commit();
    }
    async setReduction(reduction: number) {
        this._line.reduction = reduction;
        await this._parent.commit();
    }

    makeActive() {
        this.addClass("active");
        return this;
    }
    async makeInactive() {
        this.removeClass("active");
        if (this._line.quantity == 0) {
            await this._parent.removeLine(this._line);
        }
        return this;
    }
}

class SiteOrderTotal 
    extends QueryElement<HTMLElement> {

    private _container?: QueryElement<HTMLElement>;
    
    constructor(element: HTMLElement) {
        super(element);

        /*                    <dl>
                        <dt>Totaal: &euro; 5,00</dt>
                        <dl>Belastingen: &euro; 1,00</dl>
                    </dl>*/
        this.redraw(undefined);
    }

    redraw(order?: Entities.Order) {
        if (this._container != null)
            this._container.remove();
        this._container = QueryElement.QueryElement("dl");

        if (order != null) {
            let total = QueryElement.QueryElement("dt").setText(`Totaal: € ${order.getTotal().toFixed(2)}`);
            let taxes = QueryElement.QueryElement("dl").setText(`Belastingen: € 0,00`);

            this._container.append(total);
            this._container.append(taxes);
        }


        this.append(this._container);
    }
}