namespace Indexes {
    export class Order
        implements IBaseIndex<Entities.Order> {
        
        _id = "Order";

        views = {
            byClosed: {
                map: function (doc: Entities.Order) {
                    if (doc.type === 'Order') {
                        emit(doc.closed);
                    }
                }
            },
            byDateTimeStart: {
                map: function (doc: Entities.Order) {
                    if (doc.type === 'Order') {
                        emit(doc.dateTimeStart);
                    }
                }
            },
            byUserId: {
                map: function (doc: Entities.Order) {
                    if (doc.type === 'Order') {
                        emit(doc.userId);
                    }
                }
            }
        }
    }
}