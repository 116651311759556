class SiteElementDropdown
    extends AbstractSiteElement {

    private readonly _input: SiteHelperText;
    private readonly _dropdownIcon: QueryElement<HTMLElement>;

    constructor(element: HTMLElement, sitePage: SitePage) {
        super(element, sitePage);
        
        let icon = this.getAttr("data-icon");
        let label = this.getAttr("data-label");
        let queryName = this.getAttr("data-query") ?? throwError("No query has been set.");
    
        // -- Create input element
        let input = QueryElement.QueryElement("div")
                    .addClass("text")
                    .prependTo(this);
        
        if (icon != null)
            QueryElement.QueryElement("i")
                .setText(icon)
                .appendTo(input);

        this._input = new SiteHelperText(sitePage.getKeyboard())
            .appendTo(input);

        this._input.on("focus", () => this.makeActive());
        this._input.on("blur", () => this.makeInactive());


        this._dropdownIcon = QueryElement.QueryElement("i")
            .setText("")
            .appendTo(input);

        // -- Append label
        if (label != null)
            QueryElement.QueryElement("label")
                .setAttr("translate", "")
                .setHtml(label)
                .prependTo(this);

        // -- Append query
        let repository = sitePage.getRepository();
        let query = this.query(".results").create<SiteHelperQuery, HTMLElement>(
            a => new SiteHelperQuery(a, queryName, repository)) ?? throwError("Unable to find query");

        query.clickEvent.on(async a => { this._input.setValue(a.model.getResult()._id); });
        this._input.on("change", async (e: CustomEvent) => {
            query.setFilter({
                input: e.detail
            })      
        });
        
    }

    setValue(value: any) {
        this._input.setValue(value);
    }
    getValue() {
        return this._input.getValue();
    }

    makeActive() {
        this.addClass("active");
    }
    makeInactive() {
        this.removeClass("active");
    }
}