namespace Pages {
    export class SettingsPage {
        private readonly _repository: Repository;

        constructor(repository: Repository) {
            this._repository = repository;
        }
        async index() {
            var settings = await this._repository.getSettings();
            return new PageViewResult("Settings/", settings);
        }

        async update({ id, model }: { id: string, model: Entities.Settings }) {
            await this._repository.putSettings(model);
            return new PageActionResult(
                new PageActionReload()
            );
        }
    }
}