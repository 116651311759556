namespace Indexes {
    export class Purchase
        implements IBaseIndex<Entities.Purchase> {
        
        _id = "Purchase";
        views = {
            byBarcode: {
                map: function (doc: Entities.Purchase) {
                    if (doc.type === 'Purchase' && doc.barcode != null) {
                        emit(doc.barcode.toLowerCase());
                    }
                }
            },
            byName: {
                map: function (doc: Entities.Purchase) {
                    if (doc.type === 'Purchase') {
                        emit(`${Object.values(doc.name)[0]} ${Object.values(doc.description)[0]}`.toLowerCase());
                    }
                }
            },
            byNamePartial: {
                map: function (doc: Entities.Purchase) {
                    if (doc.type === 'Purchase') {
                        if (doc.name != null) {
                            for(var x of Object.values(doc.name)) {
                                if (x != null) {
                                    for(var y of x.split(" ")) {
                                        emit(y.toLowerCase());
                                    }
                                }
                            }
                        }
                        if (doc.description != null) {
                            for(var x of Object.values(doc.description)) {
                                if (x != null) {
                                    for(var y of x.split(" ")) {
                                        emit(y.toLowerCase());
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}