namespace Entities {

    export class OrderLine {
        orderableId: string;
        name: string;
        description: string;
        quantity: number;
        quantityPerBunch: number;
        reduction: number = 0;
        price: number;

        constructor(orderable: IOrderable & BaseEntity, quantity: number) {
            this.name = Site.translate(orderable.name);
            this.description = "";
            this.quantity = quantity;
            this.quantityPerBunch = orderable.quantityPerBunch;
            this.price = orderable.price;
            this.orderableId = orderable._id ?? throwError("Unknown ID given.");
        }

        totalPrice() {
            return this.quantity * this.price;
        }
    }
    
    export class Order
        extends BaseEntity {

        customerId?: string;
        userId: string;
        dateTimeStart: number;
        dateTimeEnd?: number;
        closed: boolean;
        orderLines: OrderLine[] = [];

        constructor(userId: string) {
            super();

            this.closed = false;
            this.userId = userId;
            this.dateTimeStart = Date.now();
        }

        getLine(orderable: IOrderable & BaseEntity) {
            for(var line of this.orderLines) {
                if (line.orderableId == orderable._id)
                    return line;
            }

            return null;
        }

        getTotal() {
            return this.orderLines
                .map(a => a.totalPrice())
                .reduce((total, current) => total + current, 0);
        }

        static cast(obj: any, prototype: any) {
            Object.setPrototypeOf(obj, prototype); 
            for(let orderLine of obj.orderLines) {
                Object.setPrototypeOf(orderLine, OrderLine.prototype);
            }
        }
    }
}