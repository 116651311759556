namespace Indexes {
    export class User
        implements IBaseIndex<Entities.User> {
        
        _id = "User";
        views = {
            byLastName: {
                map: function (doc: Entities.User) {
                    if (doc.type === 'User') {
                        emit(`${doc.lastName}|${doc.firstName}`);
                    }
                }
            },
            byQuery: {
                map: function (doc: Entities.User) {
                    if (doc.type === 'User') {
                        for(var lastNamePart of doc.lastName.split(" ")) {
                            emit(lastNamePart.toLowerCase());
                        }
                        for(var firstNamePart of doc.firstName.split(" ")) {
                            emit(firstNamePart.toLowerCase());
                        }
                        emit(doc.username);
                    }
                }
            }
        }
    }
}