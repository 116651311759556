namespace Entities {
    interface IAddress {
        line1?: string;
        line2?: string;
        zipCode?: string;
        city?: string;
        state?: string;
        country?: string;
    }
    export class Address {
        line1?: string;
        line2?: string;
        zipCode?: string;
        city?: string;
        state?: string;
        country?: string;

        constructor(address: IAddress) {
            this.line1 = address.line1;
            this.line2 = address.line2;
            this.zipCode = address.zipCode;
            this.city = address.city;
            this.state = address.state;
            this.country = address.country;
        }
    }
}