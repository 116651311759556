namespace Entities {

    export interface IOrderable {
        name: LanguageValues;
        quantityPerBunch: number;
        price: number;
    }
    interface IPurchase
        extends IOrderable {

        vbnCode: number;
        vbnGroupCodes: number[];
        name: LanguageValues;
        description: LanguageValues;
        quantity: number;
        quantityPerBunch: number;
        price: number;
        supplier: string;
        manufacturer: string;
        date: Date;

        barcode?: string;
        tags?: string[];
        features?: { [key: string]: string }
        attachment?: string;
    }
    export class Purchase
        extends BaseEntity
        implements IPurchase {
        
        vbnCode: number;
        vbnGroupCodes: number[];
        name: LanguageValues;
        description: LanguageValues;
        quantity: number;
        quantityPerBunch: number;
        quantitySold: number;
        price: number;
        supplier: string;
        manufacturer: string;
        date: Date;

        barcode?: string;
        tags?: string[];
        features?: { [key: string]: string }
        attachment?: string;

        constructor(purchase: IPurchase) {
            super();

            this.vbnCode = purchase.vbnCode;
            this.vbnGroupCodes = purchase.vbnGroupCodes;
            this.name = purchase.name;
            this.description = purchase.description;
            this.barcode = purchase.barcode;
            this.quantityPerBunch = purchase.quantityPerBunch;
            this.quantity = purchase.quantity;
            this.price = purchase.price;
            this.supplier = purchase.supplier;
            this.manufacturer = purchase.manufacturer;
            this.date = purchase.date;
            
            this.tags = purchase.tags;
            this.features = purchase.features;
            this.attachment = purchase.attachment;

            this.quantitySold = 0;
        }
    }
}