namespace Queries {
    interface ISettingsThemeQuery
        extends IQueryFilter {

    }

    interface IResult {
        _id: string
        name: string;
    }

    export class SettingsThemeQuery
        implements ITQueryInfo<Indexes.None, Entities.None, ISettingsThemeQuery, IResult> {
        

        constructor() {
        }

        async execute(context: ITQueryRequestContext<Indexes.None, Entities.None>) {
            return {
                docs: [{
                    _id: "light",
                    name: "Licht"
                },{
                    _id: "dark",
                    name: "Donker"
                }],
                totalResults: 2
            };
        }
        
    }
}