namespace Queries {
    interface IPurchaseQueryFilter
        extends IQueryFilter {

    }

    interface IPurchaseOrdered {
        ordered?: number;
    }

    export class PurchaseQuery
        implements ITQueryInfo<Indexes.Purchase, Entities.Purchase, IPurchaseQueryFilter, Entities.Purchase> {
        
        private readonly _query: RepositoryQuery<Entities.Purchase, Indexes.Purchase>;

        constructor(repository: Repository) {
            this._query = repository.query<Entities.Purchase, Indexes.Purchase, new () => Indexes.Purchase>(Indexes.Purchase);
        }

        async execute(context: ITQueryRequestContext<Indexes.Purchase, Entities.Purchase>) {

            let query = this._query;
            if (context.filter.input != null)
                query = query.whereContainsAll("byNamePartial", context.filter.input.split(" ").filter(Boolean));

            var result  = await query
                .orderBy(context.orderBy ?? "byName")
                .skip(context.from)
                .limit(context.limit)
                .toArray();

            var order = Site.getOrder();
            
            var docs : (Entities.Purchase & IPurchaseOrdered)[] = result.docs.map(doc => ({
                ...doc,
                ordered: order?.getLine(doc)?.quantity
            }));

            return {
                docs: docs,
                totalResults: result.totalResults
            }
        }
        
    }
}