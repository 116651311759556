namespace Queries {
    interface IImportQueryFilter
        extends IQueryFilter {

    }

    interface IResult {

    }
    export class ImportQuery
        implements ITQueryInfo<Indexes.None, Entities.None, IImportQueryFilter, IResult> {

        constructor() {
        }

        async execute(context: ITQueryRequestContext<Indexes.None, Entities.None>) {
            var request = new QueryRequest("https://anoort-test.easyflor.shop/Api/Authorization", {
                method: "post",
                contentType: "json",
                credentials: "include",
                data: `{ "userName": "asdfasdf", "password": "asdfasdf" }`
            });

            var response = await request.request();

            var request2 = new QueryRequest("https://anoort-test.easyflor.shop/Api/PreOrderDate", {
                credentials: "include"
            });
            var response2 = await request2.request();
            var response2obj = await response2.json();
            console.log(response2obj);


            return {
                docs: [],
                totalResults: 0
            }
        }
        
    }
}