interface IKey {
    size: number;
    key: string;
}
type KeyDefinition = IKey | string;
type KeyboardLayouts = "us" | "us_shift" | "num";
type SpecialKeys = "KeyShift" | "KeyBackspace" | "KeyNumbers" | "KeySpacebar" | "KeyEnter";
interface IKeyboardLayout extends Array<Array<KeyDefinition>> {}

class SiteKeyboard 
    extends QueryElement<HTMLElement> {

    private _layout: KeyboardLayouts | undefined;
    private _defaultLayout: KeyboardLayouts | undefined;

    constructor(element: HTMLElement) {
        super(element);

        var input = new SiteInput();
        input.keyEvent.on(async a => {
            this.trigger("keyPress", false, a);
        })

        this.on("mousedown", (e) => {
            e.preventDefault();
            e.stopPropagation();
        });
    }

    isDefaultLayout() {
        return this._defaultLayout == this._layout;
    }

    getShiftLayout()  {
        return (this._layout + "_shift") as KeyboardLayouts;
    }

    async restoreDefaultLayout() {
        if (this._defaultLayout != null)
            this.setLayout(this._defaultLayout);
    }

    async setDefaultLayout(layout: KeyboardLayouts) {
        this._defaultLayout = layout;
        this.setLayout(layout);
    }

    async setLayout(layout: KeyboardLayouts) {
        if (this._layout == layout)
            return;
        
        this._layout = layout;

        var layoutRequest = new QueryRequest(`./dest/keyboards/${this._layout}.json`);
        var request = await layoutRequest.request();
        var keyboardLayout = (await request.json()) as IKeyboardLayout;

        this.clear();
        for(var row of keyboardLayout) {
            var currRow = new SiteKeyRow();
            for(var key of row) {
                currRow.append(new SiteKey(this, this.convertToIKey(key)))
            }
            this.append(currRow);
        }
    }

    show() {
        this.addClass("show");
    }
    hide() {
        this.removeClass("show");
    }

    convertToIKey(input: KeyDefinition): IKey {
        if (typeof input === 'string') {
            return { key: input, size: 1 };
        } else {
            return input;
        }
    }
}

class SiteKeyRow
    extends QueryElement<HTMLElement> {

    constructor() {
        super(document.createElement("div"));

        this.addClass("row");
    }

}
class SiteKey 
    extends QueryElement<HTMLElement> {

    constructor(keyboard: SiteKeyboard, key: IKey) {
        super(document.createElement("button"));
        var style = this.style() ?? throwError("Unable to get style.");

        this.on("click", async () => {
            // Check if it is a special key
            switch (key.key as SpecialKeys)
            {
                case "KeyNumbers":
                    if (keyboard.isDefaultLayout())
                        await keyboard.setLayout("num");
                    else
                        await keyboard.restoreDefaultLayout();

                    return;
                case "KeyShift":
                    if (keyboard.isDefaultLayout())
                        await keyboard.setLayout(keyboard.getShiftLayout());
                    else
                        await keyboard.restoreDefaultLayout();
                    return;
            }

            // Else pass trough
            keyboard.trigger("keyPress", false, this.getPressedKey(key));
        })

        this.addClass("key");
        style.flex = key.size.toString();
        this.setHtml(this.getKey(key));
    }
    getPressedKey(key: IKey) {
        switch (key.key as SpecialKeys)
        {
            default:
                return key.key[0];
            case "KeyBackspace":
                return "KeyBackspace";
            case "KeyEnter":
                return "KeyEnter";
            case "KeySpacebar":
                return "KeySpacebar";
        }
    }
    getKey(key: IKey) {
        switch (key.key as SpecialKeys)
        {
            default:
                return key.key[0];
            case "KeyBackspace":
                return "<i>backspace</i>";
            case "KeyEnter":
                return "<i>keyboard_return</i>";
            case "KeyNumbers":
                return "<i>dialpad</i>";
            case "KeyShift":
                return "<i>shift</i>";
            case "KeySpacebar":
                return "<i>space_bar</i>";
        }
    }

}

class SiteInput {

    private _readBarcode: boolean = false;
    private _readBarcodeString: string[] = [];
    private _readBarcodeTimeout: number = 0;

    private _character: string = "`";
    private _characterCode: number = 192;


    private _keyEvent: QueryEventAsync<string>;
    get keyEvent(){ return this._keyEvent.expose(); } 

    private _barcodeEvent: QueryEventAsync<string>;
    get barcodeEvent(){ return this._barcodeEvent.expose(); } 

    constructor() {
        this._keyEvent = new QueryEventAsync<string>();
        this._barcodeEvent = new QueryEventAsync<string>();

        QueryElement.get(document.body).on("keydown", (e: KeyboardEvent) => {
            if(!this.handleBarcode(e.keyCode, e.key)) {
                var toReturn = "";
                if ((e.keyCode >= 48 && e.keyCode <= 57) || (e.keyCode >= 65 && e.keyCode <= 90)) {
                    toReturn = e.key;
                } else {
                    switch(e.keyCode) {
                        case 8:
                            toReturn = "KeyBackspace";
                            break;
                        case 13:
                            toReturn = "KeyEnter";
                            break;
                        case 32:
                            toReturn = "KeySpacebar";
                            break;
                    }
                }

                if (toReturn != "")
                    this._keyEvent.trigger(toReturn);
            }
        });
    }

    handleBarcode(keyCode: number, key: string): boolean {

        if (this._readBarcode) {
            if ((keyCode >= 48 && keyCode <= 57) || (keyCode >= 65 && keyCode <= 90)) {
                this.read(key);
                return true;
            }

            if (keyCode == this._characterCode) {
                this.endBarcode();
                return true;
            }
        } else if (keyCode == this._characterCode) {
            this.startBarcode();
            return true;
        }
        
        return false;
    }

    read(key: string) {
        this._readBarcodeString.push(key);
        this.prolongBarcode();
    }
    startBarcode() {
        this._readBarcode = true;
        this._readBarcodeString = [];

        this.prolongBarcode();
    }
    prolongBarcode() {
        window.clearTimeout(this._readBarcodeTimeout);
        this._readBarcodeTimeout = window.setTimeout(() => this.endBarcode(), 5000);
    }
    endBarcode() {
        this._readBarcode = false;
        window.clearTimeout(this._readBarcodeTimeout);

        if (this._readBarcodeString.length == 0)
            return;

        var barcode = this._readBarcodeString.join("");
    }
}