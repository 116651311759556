namespace Entities {

    export class Current
        extends BaseEntity {
        
        _id: string = Current.getId();        
        order?: Order;

        constructor() {
            super();
        }

        static getId() {
            return "_local/Current";
        }

        static cast(obj: any, prototype: any) {
            Object.setPrototypeOf(obj, prototype); 
            Order.cast(obj.order, Order.prototype);
        }
    }
}