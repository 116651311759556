namespace Entities {

    interface IUser {
        firstName: string;
        lastName: string;
    
        username: string;
        password: string;
    }
    export class User
        extends BaseEntity {

        firstName: string;
        lastName: string;
    
        username: string;
        password: string;

        constructor(user: IUser) {
            super();

            this.firstName = user.firstName;
            this.lastName = user.lastName;
            this.username = user.username;
            this.password = user.password;
        }

        async setPassword(password: string) {
            const salt = Hashing.generateSalt(16);
            const saltedPassword = salt + password;
            const hashedPassword = await Hashing.sha256(saltedPassword);
            this.password = `${salt}:${hashedPassword}`;
        }

        async checkPassword(password: string) {
            const [storedSalt, storedHash] = this.password.split(':');
            const saltedPassword = storedSalt + password;
            const hashedPassword = await Hashing.sha256(saltedPassword);
            return hashedPassword === storedHash;
        }
    }
}