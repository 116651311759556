namespace Pages {
    export class SystemPage {
        private readonly _repository: Repository;

        constructor(repository: Repository) {
            this._repository = repository;
        }

        async reset() {
            await this._repository.local().destroy();
            return new PageActionResult(new PageActionReload());
        }
    }
}