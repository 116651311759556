class Repository {

    private _local: PouchDB.Database<{}>
        = new PouchDB('epos');
    private _remote: PouchDB.Database<{}> | undefined;

    constructor() {
    }

    async upgrade(username: string, password: string) {
        var remote = new PouchDB(`https://db.denhaas.net/customer_${username}`, {
            auth: {
                username: username,
                password: password
            }
        });

        try {
            await remote.info();
        } catch {
            return false;
        } 

        this._remote = remote;
        return true;
    }

    put<T extends Entities.BaseEntity>(entity: T) {
        if (entity._id == "" || entity._id == null) {
            entity._id = `${entity.constructor.name}/${crypto.randomUUID()}`;
            entity.type = entity.constructor.name;
        }

        return this._local.put(entity);
    }
    async createIndex<Z extends Entities.BaseEntity, T extends Indexes.IBaseIndex<Z>>(index: T)
    {
        var model : Indexes.IPutBaseIndex = {
            _id: `_design/${index._id}`,
            views: {}
        };

        for(var x in index.views)
        {
            model.views[x] = {
                map: index.views[x].map.toString(),
                reduce: index.views[x].reduce?.toString()
            };
        }

        try {
            var localIndex : Indexes.IPutBaseIndex = await this._local.get(model._id);
            localIndex.views = model.views;

            return await this._local.put(localIndex);
        } catch {
            return await this._local.put(model);
        }
    }

    async getSettings() {
        try {
            return await this._local.get<Entities.Settings>("_local/Settings");
        } catch {
            return undefined;
        }
    }
    async putSettings(settings: Entities.Settings) {
        await this._local.put(settings);
    }

    async getArray<T extends Entities.BaseEntity>(ids: string[], classRef: Entities.IBaseEntityWithCast<T>) {
        let dictionary : Record<string, T | undefined> = {};
        for(const id of ids)
        {
            try {
                dictionary[id] = await this.get<T>(id, classRef);
            } catch {
                dictionary[id] = undefined;
            }
        }
        return dictionary;
    }

    async get<T extends Entities.BaseEntity>(id: string, classRef: Entities.IBaseEntityWithCast<T>) {
        var getter = await this._local.get<T>(id);
        classRef.cast(getter, classRef.prototype);
        return getter;
    }

    private isObject(value: any): boolean {
        return typeof value === 'object' && value !== null && !this.isArray(value);
    }
    private isArray(value: any): boolean {
        return Array.isArray(value);
    }


    delete<T extends Entities.BaseEntity>(model: T) {
        return this._local.remove(model);
    }

   async sync() {
        if (this._remote != null)
            await this._local.sync(this._remote, {
                live: false,
                retry: true
            });
    }
    async compactOffline() {
        await this._local.compact();
    }
    async compactOnline() {
        if (this._remote != null)
            await this._remote.compact();
    }

    query<Z extends Entities.BaseEntity, I extends Indexes.IBaseIndex<Z>, T extends { new(): I; }>(type: T): RepositoryQuery<Z, I> {
        return new RepositoryQuery<Z, I>(this._local, new type());
    }
    keys<Z extends Entities.BaseEntity, I extends Indexes.IBaseIndex<Z>, T extends { new(): I; }>(type: T): RepositoryKeys<Z, I> {
        return new RepositoryKeys<Z, I>(this._local, new type());
    }

    viewCleanup() {
        this._local.viewCleanup();
    }

    local() {
        return this._local;
    }

}