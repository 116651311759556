namespace Queries {
    interface ICustomerCountryQuery
        extends IQueryFilter {

    }

    interface IPurchaseOrdered {
        ordered?: number;
    }

    interface IResult {
        name: string;
    }

    export class CustomerCountryQuery
        implements ITQueryInfo<Indexes.Customer, Entities.Customer, ICustomerCountryQuery, IResult> {
        
        private readonly _query: RepositoryKeys<Entities.Customer, Indexes.Customer>;

        constructor(repository: Repository) {
            this._query = repository.keys<Entities.Customer, Indexes.Customer, new () => Indexes.Customer>(Indexes.Customer);
        }

        async execute(context: ITQueryRequestContext<Indexes.Customer, Entities.Customer>) {

            let query = this._query;
            let keys = await query.getKeys("byCountry");
            keys = keys.filter(a => a.startsWith(context.filter.input ?? ""));

            return {
                docs: keys.map(a => {
                    return {
                        _id: a,
                        name: a
                    };
                }),
                totalResults: keys.length
            }
        }
        
    }
}