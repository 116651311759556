namespace Entities {

    export abstract class BaseEntity  {
        _id: string = "";
        _rev: string = "";
        type?: string;

        static cast(obj: any, prototype: any){
            Object.setPrototypeOf(obj, prototype); 
        }
    }

    export interface IBaseEntityWithCast<T extends BaseEntity> {
        new(...args: any[]): T; // Constructor signature
        cast(instance: any, prototype: any): void; // Static method signature
    }


}