namespace Entities {

    interface IPrinter {
        ipAddress: string;
        name: string;
        location: string;
    }
    export class Printer
        extends BaseEntity {

        ipAddress: string;
        name: string;
        location: string;

        constructor(user: IPrinter) {
            super();

            this.ipAddress = user.ipAddress;
            this.name = user.name;
            this.location = user.location;
        }
    }
}