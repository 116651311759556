interface IQueryFilter {
    input?: string;
}
interface IQueryInfo {
    execute(context: IQueryRequestContext): Promise<IQueryResponseContext>
}
interface ITQueryInfo<
        TQuery extends Indexes.IBaseIndex<TEntity>, 
        TEntity extends Entities.BaseEntity,
        TFilter extends IQueryFilter, 
        TResult
    > {
    execute(context: ITQueryRequestContext<TQuery, TEntity>): Promise<ITQueryResponseContext<TResult>>
}
interface IQueryRequestContext {
    from: number;
    limit: number;

    filter: IQueryFilter;
    orderBy?: any;
}
interface ITQueryRequestContext<
        TQuery extends Indexes.IBaseIndex<TEntity>, 
        TEntity extends Entities.BaseEntity
    > extends IQueryRequestContext{
    orderBy?: keyof TQuery['views'];
}
interface ITQueryFilterRequestContext<
        TQuery extends Indexes.IBaseIndex<TEntity>, 
        TEntity extends Entities.BaseEntity,
        TFilter extends IQueryFilter
    > extends IQueryRequestContext{
    filter: TFilter;
}
interface IQueryResponseContext {
    docs: any[];
    totalResults: number;
}
interface ITQueryResponseContext<T>
    extends IQueryResponseContext {
    docs: T[];
}

interface ISiteHelperQueryConfig {
    perPage: number,
    extraTop: number,
    extraBottom: number
}
interface ISiteHelperQuery
    extends IQueryElement {

    statisticsEvent: IQueryEventAsync<number>;
    templateEvent: IQueryEventAsync<ISiteHelperQueryTemplate>;
    clickEvent: IQueryEventAsync<QueryEventedModel<ISiteHelperQueryRequestItem>>;

    resize(): void;
    refresh(): Promise<void>;

    getFilter(): IQueryFilter | undefined;
    setFilter(filter: IQueryFilter): Promise<void>;
    getOrderBy(): string | undefined;
    setOrderBy(orderBy: string): Promise<void>;
    setTemplate(template: string): Promise<void>;
    getTemplate(): string | undefined;

    getPageItems(): ISiteHelperQueryRequestItem[];
}
interface ISiteHelperQueryRequest
    extends IQueryElement {

}
interface ISiteHelperQueryRequestItem
    extends IQueryElement {

    getResult(): any;
}
interface ISiteHelperQueryTemplate {
    name: string;
}

class SiteHelperQueryTemplate {
    public name: string;
    public template: QueryTemplate;
    public width: number;
    public height: number;
    public className: string;

    constructor(name: string, className: string, template: QueryTemplate, width: number, height: number) {
        this.name = name;
        this.className = className;
        this.template = template;
        this.width = width;
        this.height = height;
    } 
}

class SiteHelperQuery
    extends QueryElement<HTMLElement>
    implements ISiteHelperQuery {

    private _templates: SiteHelperQueryTemplate[] = [];
    private _template: SiteHelperQueryTemplate 
        = new SiteHelperQueryTemplate("", "", new QueryTemplate(""), 0, 0);
    private _height: number = 0;
    private _width: number = 0;
    private _scrollTop: number = 0;

    private _orderBy?: any;
    private _filter?: IQueryFilter;

    private readonly _queryName: string;
    private readonly _repository: Repository;
    private readonly _style: CSSStyleDeclaration;
    private readonly _container: IQueryElement;
    private readonly _pages: SiteHelperQueryExecutor[]
        = [];
    
    private readonly _statisticsEvent: QueryEventAsync<number>
        = new QueryEventAsync<number>();
    private readonly _clickEvent: QueryEventAsync<QueryEventedModel<ISiteHelperQueryRequestItem>>
        = new QueryEventAsync<QueryEventedModel<ISiteHelperQueryRequestItem>>();
    private readonly _templateEvent: QueryEventAsync<ISiteHelperQueryTemplate>
        = new QueryEventAsync<ISiteHelperQueryTemplate>();

    private _statistics?: number;

    private readonly _config = {
        perPage: 25,
        extraTop: 5,
        extraBottom: 5
    };

    public get clickEvent() { return this._clickEvent.expose(); }
    public get statisticsEvent() { return this._statisticsEvent.expose(); }
    public get templateEvent() { return this._templateEvent.expose(); }

    static getQuery(queryName: string, repository: Repository) {
        switch(queryName) {
            case "orderQuery":
                return new Queries.OrderQuery(repository);
            case "purchaseQuery":
                return new Queries.PurchaseQuery(repository);
            case "customerQuery":
                return new Queries.CustomerQuery(repository);
            case "customerCountryQuery":
                return new Queries.CustomerCountryQuery(repository);
            case "userQuery":
                return new Queries.UserQuery(repository);
            case "printerQuery":
                return new Queries.PrinterQuery(repository);
            case "importQuery":
                return new Queries.ImportQuery();
            case "settingsThemeQuery":
                return new Queries.SettingsThemeQuery();
            default:
                return throwError(`Unable to find query ${queryName}.`);
        }
    }

    constructor(
        elem: HTMLElement,
        queryName: string,
        repository: Repository) {

        super(elem);

        this._container = QueryElement.QueryElement("div")
            .appendTo(this);

        this._container.on("mousedown", (e: MouseEvent) => {
            let position = this.position();
            let results = this.get();
            let columns = this.getColumns();
            if (position === null || results === null)
                return;

            let posY = e.pageY - position.top + results.scrollTop;
            let posX = e.pageX - position.left;

            let row = Math.floor(posY / this._template.height);
            let column = Math.floor(posX / (this._width / columns));
            let item = ((row * columns) + column);

            //console.log(`Item: ${item}; Row: ${row}; Column: ${column}`);

            let requester = Math.floor(item / this._config.perPage);
            let itemToGet = item % this._config.perPage;

            let resultItem = this._pages[requester].getResultItem(itemToGet);

            this._clickEvent.trigger(new QueryEventedModel(resultItem, e));
        });

        let style = this._container.style();
        if (style === undefined)
            throw "Couldn't access style attribute!";

        this._style = style;
        this._queryName = queryName;
        this._repository = repository;

        let firstChild = this.query(".templates").first();

        if (firstChild === null)
            throw "No template found!";

        // Get templates
        for (var child of firstChild.children())
        {
            var templateElement = child.firstChild()?.get() as HTMLElement;
            var templateName = child.getAttr("data-name") as string;
            var templateClassName = child.getAttr("class") as string;
            var templateHeight = templateElement.offsetHeight;
            var templateWidth = templateElement.offsetWidth;

            var template = new SiteHelperQueryTemplate(
                templateName,
                templateClassName,
                new QueryTemplate(child.getHtml() || ""),
                templateWidth,
                templateHeight);
            
            this._templates.push(template);
        }
        
        firstChild.remove();

        // Select template
        if (this._templates.length == 0)
            throw "No templates found!";

        var f = throttle(() => this.render(), 250);
        this.on("scroll", () => {
            this._scrollTop = this.scrollTop();
            f();
        });
        
        this.resize();
        this.setTemplate(this._templates[0].name);
    }

    resize() {
        let elem = this.get();
        if (elem === null)
            return 0;
        
        elem.scrollTop = this._scrollTop;
        this._height = elem.clientHeight;

        if (this._width != elem.clientWidth) {
            this._width = elem.clientWidth;

            var results = this._statistics ?? 0;
            this._style.height = (Math.ceil(results / this.getColumns()) * this._template.height) + "px";

            this.getPages().forEach((a) => a.resize());
            
            console.log(`Resize: Width: ${this._width}; Height: ${this._height}`);
        } else {
            console.log(`Resize: Height: ${this._height}`);
        }
    }
    async refresh() {
        // Need to check for undefined, test of _pages[x] will create a undef value
        for (let page of this._pages.filter(a => a !== undefined)) {
            await page.setExpired();
            if (page.isVisible())
                await page.request();
        }
    }

    private request(from: number) {
        if (this._template == null)
            return null;
        
        // Add an elementRequest
        let query = SiteHelperQuery.getQuery(this._queryName, this._repository);
        let request = new SiteHelperQueryExecutor(
            this, 
            this._template.template, 
            query,
            {
                from: from,
                limit: this._config.perPage,
                filter: this._filter || {},
                orderBy: this._orderBy
            }
        );
        
        // Find first element with top higher than this item
        let elementAfter = this._container.children<SiteHelperQueryExecutor>()
            .filter(a => a.getOrder() > from)
            .first()
        
        // Append to container
        if (elementAfter == null)
            this._container.append(request);
        else
            this._container.insertBefore(request, elementAfter);
            
        return request;
    }
    setStatistics(statistics: number) {
        this._statistics = statistics;
        this._style.height = (Math.ceil(this._statistics / this.getColumns()) * this._template.height) + "px";

        this._statisticsEvent.trigger(this._statistics);
    }
    getFilter() {
        return this._filter;
    }
    async setFilter(filter: IQueryFilter) {
        this._filter = filter;
        await this.reloadResults();
    }
    getOrderBy() {
        return this._orderBy;
    }
    async setOrderBy(orderBy: string) {
        this._orderBy = orderBy;
        await this.reloadResults();
    }
    getTemplate() {
        return this._template.name;
    }
    async setTemplate(name: string) {
        var template = this._templates.filter(a => a.name == name).first();
        if (template != null) {
            this._template = template;
            this.clearPages();
            this._container.setAttr("class", template.className);
            this._templateEvent.trigger({
                name: ""
            });
            await this.reloadResults();
        }
    }
    private async reloadResults() {
        let request = this.request(0);
        if(request != null) {
            await request.request();
            this.clearPages();

            this._pages[0] = request;
        }
        this.render();
    }


    private clearPages() {
        // Scroll to top:
        let elem = this.get();
        if (elem)
            elem.scrollTop = 0;

        // Remove pages
        while (this._pages.length) {
            let page = this._pages.pop();
            page && page.remove();
        }
    }
    private getPages() {
        return this._container.children<SiteHelperQueryExecutor>();
    }
    getPageItems() {
        let array : SiteHelperQueryRequestItem[] = [];
        for(var page of this.getPages())
        {
            array.push(...page.children<SiteHelperQueryRequestItem>());
        }
        return array;
    }

    getTemplateWidth() {
        return this._template.width;
    }
    getTemplateHeight() {
        return this._template.height;
    }
    getColumns() : number {
        return Math.floor(this._width / this._template.width) || 1;
    }
    getStatisticsResults() {
        return this._statistics ?? 0;
    }

    private scrollTop() {
        let elem = this.get();
        if (elem === null)
            return 0;

        return elem.scrollTop;
    }

    private _displaying: { from: number, to: number } = {
        from: 0,
        to: 0
    };
    private async render() {
        let elem = this.get();
        if (elem === null)
            return;
        
        let scrollTop = this.scrollTop();
        let columns = this.getColumns();

        // Calculate what to display:
        let firstRow = (scrollTop / this._template.height) - this._config.extraTop;
        if (firstRow < 0)
            firstRow = 0;

        let lastRow = ((scrollTop + this._height) / this._template.height) + this._config.extraBottom;
        if (this._statistics != null)
        {
            let lastCalculatedRow = Math.ceil((this._statistics ?? 0) / columns);
            if (lastRow > lastCalculatedRow)
                lastRow = lastCalculatedRow;
        }

        let from = Math.floor(firstRow * columns / this._config.perPage);
        let to = Math.ceil(lastRow * columns / this._config.perPage);

        //console.log(`${scrollTop}; Columns: ${columns}; Results: ${this._statistics}; FirstRow: ${firstRow}; LastRow: ${lastRow}; FromPage: ${from}; ToPage: ${to}`);

        // Hide displaying.from to from
        for (let x = this._displaying.from; x < from; x++) {
            let toHide = this._pages[x];
            if (toHide !== undefined)
                toHide.hide();
        }
        // Hide to to displaying.to
        for (let x = to; x <= this._displaying.to; x++) {
            let toHide = this._pages[x];
            if (toHide !== undefined)
                toHide.hide();
        }

        this._displaying = {
            from: from,
            to: to
        };

        // Show / get pages
        for (let x = Math.max(from, this._displaying.from); x < Math.min(to, this._displaying.to); x++) {
            let result = this._pages[x];
            if (this._pages[x] === undefined) {
                let request = this.request(x * this._config.perPage);
                if (request != null) {
                    this._pages[x] = request;
                    await this._pages[x].request();
                }    
            }
            else {
                result.show();

                if (result.isExpired())
                    await result.request();
            }
        }
    }

    async remove(removeInner = true) {
        await super.remove(removeInner);
    }
}

class SiteHelperQueryExecutor
    extends QueryElement<HTMLElement>
    implements ISiteHelperQueryRequest {

    private readonly _template: QueryTemplate;
    private readonly _query: IQueryInfo;
    private readonly _context: IQueryRequestContext;
    private readonly _parent: SiteHelperQuery;
    private _results: ISiteHelperQueryRequestItem[] = [];
    private _expired: boolean;
    private _startSpacer: SiteHelperQueryRequestSpacer;
    private _endSpacer: SiteHelperQueryRequestSpacer;
    private _style: CSSStyleDeclaration;

    constructor(parent: SiteHelperQuery, template: QueryTemplate, query: IQueryInfo, context: IQueryRequestContext) {
        super(document.createElement("div"));

        let style = this.style();
        if (style === undefined)
            throw "Unable to get style!";

        this._style = style;
        this._template = template;
        this._query = query;
        this._context = context;
        this._parent = parent;
        this._expired = false;
        
        this._startSpacer = new SiteHelperQueryRequestSpacer(this._parent, "start").appendTo(this);
        this._endSpacer = new SiteHelperQueryRequestSpacer(this._parent, "end").appendTo(this);

        this.addClass("page");
    }

    resize() {
        let columns = this._parent.getColumns();
        let startSpacer = this._context.from % columns;
        
        let contextTo = this._context.from + this._context.limit;
        if (contextTo > this._parent.getStatisticsResults())
            contextTo = this._parent.getStatisticsResults();
        
        let endSpacer = columns - (contextTo % columns);
        if (endSpacer == columns)
            endSpacer = 0;

        this._startSpacer.resize(startSpacer);
        this._endSpacer.resize(endSpacer);

        let skipColumns = Math.floor(this._context.from / columns);
        this._style.top = `${skipColumns * this._parent.getTemplateHeight()}px`;

        //console.log(`Columns: ${columns}; TemplateWidth: ${this._parent.getTemplateWidth()}; Top: ${this._style.top}; StartSpacer: ${startSpacer}; EndSpacer: ${endSpacer}`);
    }

    async request() {
        this._expired = false;
        console.log(`Requesting: ${this._context.from}`);

        let request = await this._query.execute(this._context);

        // Clear the results
        for(var result of this._results) {
            await result.remove();
        }
        this._results = [];

        // Fill them
        for (let x = 0; x < request.docs.length; x++) {
            let toAppend = new SiteHelperQueryRequestItem(
                this,
                this._template,
                this._context.from + x,
                request.docs[x]
            );
            this.append(toAppend);
            this._results.push(toAppend);
        }
        this._parent.setStatistics(request.totalResults);
        this.resize();
    }
    async refresh() {
        await this.request();
    }
    getOrder() {
        return this._context.from;
    }
    getResults() {
        return this._results;
    }
    getResultItem(index: number) {
        return this._results[index];
    }
    isExpired() {
        return this._expired;
    }
    setExpired() {
        this._expired = true;
    }

    isVisible() {
        return !this.containsClass("hide");
    }
    show() {
        this.removeClass("hide");
    }
    hide() {
        this.addClass("hide");
    }
}
class SiteHelperQueryRequestItem
    extends QueryElement<HTMLElement>
    implements ISiteHelperQueryRequestItem {

    private readonly _result: any;
    private readonly _index: number;
    private readonly _executor: SiteHelperQueryExecutor;

    constructor(executor: SiteHelperQueryExecutor, template: QueryTemplate, index: number, result: any) {
        super(document.createElementFromHTML(template.render(result)));

        this._executor = executor;
        this._result = result;
        this._index = index;
    }

    public refresh() {
        return this._executor.refresh();
    }

    public getResult() {
        return this._result;
    }

    async remove(removeInner = true) {
        await super.remove(removeInner);
    }
}

class SiteHelperQueryRequestSpacer
    extends QueryElement<HTMLElement> {
    
    private _style: CSSStyleDeclaration;
    private _parent: SiteHelperQuery;

    constructor(parent: SiteHelperQuery, className: string) {
        super(document.createElement("div"));
        this.addClass("spacer");
        this.addClass(className);

        let style = this.style();
        if (style === undefined)
            throw "Unable to get style!";

        this._style = style;
        this._parent = parent;
    }

    resize(number: number) {
        this._style.width = `${number * this._parent.getTemplateWidth()}px`;
        this._style.flexGrow = number + "";
    }
}