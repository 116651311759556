namespace Stores {
    export class CurrentStore {

        private static _current?: Entities.Current;

        static async get(repository: Repository) {
            if (this._current != null)
                return this._current;

            try {
                this._current = await repository.get<Entities.Current>(Entities.Current.getId(), Entities.Current)
            } catch {
                this._current = new Entities.Current();
            }

            return this._current;
        }

        static async set(repository: Repository) {
            if (this._current != null)
                await repository.put(this._current);
        }
    }
}