class SiteButtonNavigate 
    extends QueryElement<HTMLElement> {

    private readonly _title: string;
    private readonly _href: string;
    private readonly _startPage: boolean;

    private readonly _pages: SitePages;
    private readonly _page?: SitePage;
    private readonly _submit: boolean;

    constructor(element: HTMLElement, pages: SitePages, page?: SitePage) {
        super(element);

        this._pages = pages;
        this._page = page;

        this._title = this.getAttr("data-title") ?? "";
        this._href = this.getAttr("data-href") ?? "";
        this._submit = (this.getAttr("data-submit") ?? "0") == "1";
        this._startPage = this.getAttr("data-startPage") !== null ? true : false;

        this.on("click", () => {
            this.setLocation();
        })
    }

    setLocation() {
        let id = "";
        let model: any = undefined;

        if (this._submit && this._page) {
            let current = this._page.getCurrent();
            if (current != null) {
                model = current.getModel();
                id = current.getLocation().id ?? "";
            }
        }

        this._pages.setLocation(this._title, `${this._href}${id}`, this._startPage, model);
    }
}