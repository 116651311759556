// Make navigator show Share
interface Navigator {
    share(obj: any): void;
}

// Others
interface Document {
    createElementFromHTML(html: string): HTMLElement;
}
Document.prototype.createElementFromHTML = function (html: string) {
    let template = this.createElement("template");
    template.innerHTML = html;
    return template.content.children[0] as HTMLElement;
}

interface Event {
    stop(): void;
    data?: any;
}

interface Array<T> {
    first(): T | null;
    last(): T | null;
    skip(number: number): Array<T>;
    distinct(): Array<T>;
}

Array.prototype.first = function() {
    if(this.length === 0)
        return null;
    
    return this[0];
}
Array.prototype.last = function() {
    if(this.length === 0)
        return null;
    
    return this[this.length - 1];
}
Array.prototype.distinct = function() {
    return [...new Set(this)];
}