class Translator {
    static translate(element: IQueryElement, translate: Translate)
    {
        element.query("[translate]").all(a => {
            var html = a.getHtml();
            if (html != null)
            {
                a.setHtml(
                    this.htmlTranslate(html, translate)
                );
            }
        })
    }
    static htmlTranslate(html: string, translate: Translate) {
        return html.replace(/(_[_A-Z0-9]+)/g, match => {
            return translate.translate(match) || match;
        });
    }
}