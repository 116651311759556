interface IPageLocation {
    page: string;
    view: string;
    id?: string;
}

abstract class PageResult {
    abstract execute(sitePagesLocation: SitePagesLocation, location: IPageLocation):Promise<void>;
}

class PageViewResult
    extends PageResult {

    private _page: string;
    private _model: any;

    constructor(page: string, model: any) {
        super();

        this._page = page;
        this._model = model;
    }

    async execute(sitePagesLocation: SitePagesLocation, location: IPageLocation): Promise<void> {
        var page = sitePagesLocation.getPage(this._page);
        if (page == null)
            throwError(`Unable to find page ${this._page}`);
        
        sitePagesLocation.makeActive(page, location, this._model);
    }
}

class PageActionResult
    extends PageResult {
    private _action: PageAction;

    constructor(action: PageAction) {
        super();

        this._action = action;
    }

    async execute(sitePagesLocation: SitePagesLocation, location: IPageLocation): Promise<void> {
        this._action.execute(sitePagesLocation);
    }
}

abstract class PageAction {
    private readonly _next?: PageAction;
    constructor(nextAction?: PageAction) {
        this._next = nextAction;
    }
    async execute(sitePageLocation: SitePagesLocation) {
        try {
            await this._execute(sitePageLocation);
            await this._next?.execute(sitePageLocation);
        } catch (error) {
            return throwError("Error in PageAction chain");
        }
    }
    protected abstract _execute(sitePageLocation: SitePagesLocation): Promise<void>;
}

class PageActionNone
    extends PageAction {
    protected async _execute(sitePageLocation: SitePagesLocation): Promise<void> {
        
    }        
}

class PageActionBack 
    extends PageAction {

    protected async _execute(sitePageLocation: SitePagesLocation): Promise<void> {
        sitePageLocation.goBack(1);
    }
}

class PageActionEvent
    extends PageAction {
    private _event: string;
    
    constructor(event: string, nextAction?: PageAction) {
        super(nextAction);

        this._event = event;
    }
    protected async _execute(sitePageLocation: SitePagesLocation): Promise<void> {
        sitePageLocation.trigger("ee.event", true, this._event);
    }
}

class PageActionRefresh
    extends PageAction {
    constructor(nextAction?: PageAction) {
        super(nextAction);
    } 
    protected async _execute(sitePageLocation: SitePagesLocation): Promise<void> {
        sitePageLocation.refresh();
    }
}

class PageActionReload
    extends PageAction {
    constructor(nextAction?: PageAction) {
        super(nextAction);
    } 
    protected async _execute(sitePageLocation: SitePagesLocation): Promise<void> {
        location.reload();
    }
}


class PageMap {
    private static get _pageMap() {
        return {
            "Customers": Pages.CustomersPage,
            "Home": Pages.HomePage,
            "Assortment": Pages.AssortmentPage,
            "Pay": Pages.PayPage,
            "Users": Pages.UsersPage,
            "Import": Pages.ImportPage,
            "Settings": Pages.SettingsPage,
            "Orders": Pages.OrdersPage,
            "System": Pages.SystemPage,
            "Printers": Pages.PrintersPage
        };
    }

    static parsePageLocation(location: string): IPageLocation {
        const parts = location.split('/');

        if (parts.length < 1) {
            throw new Error('Invalid format. Expected at least two parts in the format: Page[/View][/Id]');
        }

        const page = parts[0];
        const view = parts.length > 1 ? parts[1] : undefined;
        const id = parts.length > 2 ? parts.slice(2).join('/') : undefined;

        return {
            page: page,
            view: view || "index",
            id: id
        };
    }

    static hasPageMap(className: string) {
        return className in this._pageMap;
    }

    static createInstance(className: string, repository: Repository) {
        if (this.hasPageMap(className)) {
            return new this._pageMap[className as keyof typeof this._pageMap](repository);
        } else {
            return throwError(`Unable to create class: ${className}`)
        }
    }

    static callMethod(instance: any, methodName: string, args: { [key: string]: any }) {
        if (methodName in instance && typeof instance[methodName] === 'function') {
            return instance[methodName](args) as Promise<PageResult>;
        } else {
            return throwError(`Method ${methodName} not found in instance`);
        }
    }
}