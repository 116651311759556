namespace Indexes {
    export class Printer
        implements IBaseIndex<Entities.Printer> {
        
        _id = "Printer";

        views = {
            byName: {
                map: function (doc: Entities.Printer) {
                    if (doc.type === 'Printer') {
                        emit(doc.name);
                    }
                }
            },
            byQuery: {
                map: function (doc: Entities.Printer) {
                    if (doc.type === 'Printer') {
                        for(var namePart of doc.name.split(" ")) {
                            emit(namePart.toLowerCase());
                        }
                        for(var locationPart of doc.location.split(" ")) {
                            emit(locationPart.toLowerCase());
                        }
                    }
                }
            }
        }
    }
}