type ClauseOperations = "AND" | "OR";
type Clause = {
    operation: ClauseOperations;
    criterias: Array<(() => Promise<string[]>) | Clause>;
};

interface IRepositoryQueryResult<E extends Entities.BaseEntity> {
    docs: E[],
    totalResults: number,
    timeElapsed: number
}

class RepositoryKeys<
    E extends Entities.BaseEntity,
    I extends Indexes.IBaseIndex<E>
> {
    private _index: string;
    private _db: PouchDB.Database<{}>;
    constructor(db: PouchDB.Database, index: I) {
        this._db = db;
        this._index = index._id;
    }

    public async getKeys<K extends keyof I['views']>(view: K) {
        const response = await this._db.query(`${this._index}/${view as string}`, {
            reduce: false,
            include_docs: false,
        });
        const keys = response.rows.map((row) => row.key as string);
        return [...new Set(keys)];
    }
}

interface IRepositoryQueryOrderField {
    field: string;
    descending: boolean;
}


class RepositoryQuery<
  E extends Entities.BaseEntity,
  I extends Indexes.IBaseIndex<E>
> {
    private _db: PouchDB.Database;
    private _index: string;
    private _clauses: Clause[] = [];
    private _limitValue?: number;
    private _skipValue?: number;
    private _orderField?: IRepositoryQueryOrderField;
    private _currentClause: Clause = {
        criterias: [],
        operation: "AND"
    };
    private _timer: number;

    constructor(db: PouchDB.Database, index: I) {
        this._db = db;
        this._index = index._id;

        this._timer = performance.now();
    }
    public orderBy<K extends keyof I['views']>(field: K, descending: boolean = false): RepositoryQuery<E, I> {
        this._orderField = {
            field: field as string,
            descending: descending
        };
        return this;
    }
    public limit(value: number): RepositoryQuery<E, I> {
        this._limitValue = value;
        return this;
    }

    public skip(value: number): RepositoryQuery<E, I> {
        this._skipValue = value;
        return this;
    }
    public whereContains<K extends keyof I['views']>(view: K, value: string): RepositoryQuery<E, I> {
        this._currentClause.criterias.push(() => this.containsSearch(view as string, value));
        return this;
    }
    public whereContainsAll<K extends keyof I['views']>(view: K, value: string[]): RepositoryQuery<E, I> {
        this.openSubclause("AND");
        for(var x of value)
        {
            this.whereContains(view, x);
        }
        this.closeSubclause();
        return this;
    }
    public whereContainsAny<K extends keyof I['views']>(view: K, value: string[]): RepositoryQuery<E, I> {
        this.openSubclause("OR");
        for(var x of value)
        {
            this.whereContains(view, x);
        }
        this.closeSubclause();
        return this;
    }
    public whereEquals<K extends keyof I['views']>(view: K, value: any): RepositoryQuery<E, I> {
        this._currentClause.criterias.push(() => this.equalsSearch(view as string, [value]));
        return this;
    }
    public whereIn<K extends keyof I['views']>(view: K, value: any[]): RepositoryQuery<E, I> {
        this._currentClause.criterias.push(() => this.equalsSearch(view as string, value));
        return this;
    }

    public openSubclause(operation: ClauseOperations): RepositoryQuery<E, I> {
        const newClause: Clause = { operation: operation, criterias: [] };
        this._currentClause.criterias.push(newClause);
        this._currentClause = newClause;
        this._clauses.push(this._currentClause);
        return this;
    }

    public closeSubclause(): RepositoryQuery<E, I> {
        var pop = this._clauses.pop();
        if (pop === undefined)
            throw "No open clauses found.";

        this._currentClause = pop;
        return this;
    }

    private async handleClause(clause: Clause): Promise<string[]> {
        let resultIds: string[] = [];
        const idsLists = await Promise.all(clause.criterias.map((criteria) => {
            if (typeof criteria === "function") {
                return criteria();
            } else {
                return this.handleClause(criteria); // Recursive call to handle nested clauses
            }
        }));

        if (idsLists.length == 0)
            return [];

        const ids = idsLists.reduce((prev, curr) => {
            if (clause.operation === "AND") {
                return prev.filter((id) => curr.includes(id));
            } else {
                return this.uniq([...prev, ...curr]);
            }
        }, idsLists[0]);

        resultIds = this.uniq([...resultIds, ...ids]);
        return resultIds;
    }

    private async containsSearch(view: string, value: string): Promise<string[]> {
        try {
            const response = await this._db.query(`${this._index}/${view}`, {
                startkey: value,
                endkey: value + "\ufff0",
                reduce: false,
            });
        
            return response.rows.map((row) => row.id);
        } catch(e) {
            throw `Contains failed: ${e}`;
        }
    }
    private async equalsSearch(view: string, value: any[]): Promise<string[]> {
        try {
            const response = await this._db.query(`${this._index}/${view}`, {
                keys: value,
                reduce: false,
            });
        
            return response.rows.map((row) => row.id);
        } catch(e) {
            throw `Equals failed: ${e}`;
        }
    }

    private uniq(array: any[]): any[] {
        return Array.from(new Set(array));
    }

    public async toArray(): Promise<IRepositoryQueryResult<E>> {
        let allIds: string[] = [];
        const hasCriteria = this._currentClause.criterias.length > 0;

        if (hasCriteria) {
            allIds = await this.handleClause(this._currentClause);
        }

        if (this._orderField != null) {
            // Fetch all keys from the relevant view
            const response = await this._db.query(`${this._index}/${this._orderField.field}`, {
                reduce: false,
                include_docs: false,
            });
            let orderedIds = response.rows.map((row) => row.id);
            if (this._orderField.descending)
                orderedIds = orderedIds.reverse();
            
            if (hasCriteria) {
                // Intersect this ordered list with allIds
                allIds = orderedIds.filter((id) => allIds.includes(id));
            } else {
                allIds = orderedIds;
            }
        } else if (!hasCriteria) {
            const allDocs = await this._db.allDocs<E>({ 
                include_docs: false 
            });
            allIds = allDocs.rows.map(row => row.id);
        }

        const totalResults = allIds.length;
        if (this._limitValue !== undefined || this._skipValue !== undefined) {
            const skip = this._skipValue ?? 0;
            const limit = this._limitValue ?? allIds.length; 
            
            allIds = allIds.slice(skip, skip + limit);
        }

        const docs = await this._db.allDocs<E>({
            include_docs: true,
            keys: allIds,
        });

        return {
            // @ts-ignore
            docs: docs.rows.map((row) => row.doc as E),
            totalResults: totalResults,
            timeElapsed: performance.now() - this._timer 
        };
    }
}
