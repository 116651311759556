interface ISitePagesLocationHistory {
    title: string;
    href: string;
}

interface ISitePageEvents {
    hide(): void;
    show(): void;
}

class SitePages
    extends QueryElement<HTMLElement> {

    private readonly _pages: SitePage[];
    private readonly _location: SitePagesLocation | null;
    private readonly _pageEvents: ISitePageEvents;
    private readonly _site: Site;
    private _activePage?: SitePage;

    constructor(element: HTMLElement, events: ISitePageEvents, site: Site) {
        super(element);

        var location = this.query(".location").create<SitePagesLocation, HTMLElement>(a => new SitePagesLocation(a, this));
        this._location = location;

        this._site = site;
        this._pages = this.query(".page").createAll<SitePage, HTMLElement>(a => new SitePage(a, this));
        this._pageEvents = events;
        
        this.setLocation("<i>home</i>", "Home/", true);
        this._pageEvents.hide();
    }

    resize() {
        this._activePage?.trigger("ee.resize", false);
    }

    getKeyboard() {
        return this._site.getKeyboard();
    }

    getRepository() {
        return this._site.getRepository();
    }


    getPage(pageName: string) {
        for(var page of this._pages)
        {
            if (page.check(pageName))
            {
                return page;
            }
        }
    }

    makeActive(page: SitePage, location: IPageLocation, model?: any) {
        if (this._activePage != null)
            this.makeInactive(this._activePage);

        this._activePage = page;
        page.makeActive(location, model);
    }
    makeInactive(page: SitePage) {
        page.makeInactive();
    }

    setLocation(title: string, href: string, startPage: boolean, model?: any) {
        this._pageEvents.show();
        this._location?.setLocation(title, href, startPage, model);
    }

    getTranslate() {
        return this._site.getTranslate();
    }

    hide() {
        this._pageEvents.hide();
    }
}

class SitePageCurrent {

    private _location: IPageLocation;
    private _model: any;

    constructor(location: IPageLocation, model: any) {
        this._location = location;
        this._model = model;
    }

    getLocation() {
        return this._location;
    }
    getModel() {
        return this._model;
    }
}
class SitePage
    extends QueryElement<HTMLElement> {

    private readonly _path: string;
    private readonly _sitePages: SitePages;
    private _elementsById?: { [key: string]: AbstractSiteElement; };
    private _isStarted: boolean = false;

    constructor(element: HTMLElement, sitePages: SitePages) {
        super(element);

        var path = this.getAttr("data-path");
        if (path == null)
            throw `No path found on this page: ${element}`;
        
        this._path = path;
        this._sitePages = sitePages;
    }

    start() {
        // --- Create all elements
        this.query('button[data-href]').createAll<SiteButtonNavigate, HTMLElement>(a => new SiteButtonNavigate(a, this._sitePages, this));

        let elements = this.query('.element-text').createAll<AbstractSiteElement, HTMLElement>(a => new SiteElementText(a, this));
        elements = elements.concat(this.query('.element-dropdown').createAll<AbstractSiteElement, HTMLElement>(a => new SiteElementDropdown(a, this)));
        elements = elements.concat(this.query('.element-checkbox').createAll<AbstractSiteElement, HTMLElement>(a => new SiteElementCheckbox(a, this)));

        this._elementsById = elements.reduce((acc, obj) => {
            var id = obj.getId();
            if (id != null)
                acc[id] = obj;
            return acc;
        }, {} as {[key: string]:AbstractSiteElement});

        this.query('.element-search').createAll<SiteElementSearch, HTMLElement>(a => new SiteElementSearch(a, this));

        // --- Do translation
        Translator.translate(this, this._sitePages.getTranslate());

        this._isStarted = true;
    }

    getElementById(id: string) {
        if (this._elementsById != null)
            return this._elementsById[id];
        else
            return undefined;
    }
    getKeyboard() {
        return this._sitePages.getKeyboard();
    }
    getRepository() {
        return this._sitePages.getRepository();
    }

    check(path: string) {
        return this._path == path;
    }

    private _current?: SitePageCurrent;
    makeActive(location: IPageLocation, model?: any) {
        this.addClass("show");

        if (this._isStarted == false)
            this.start();

        this.setCurrent(location, model);
    }
    makeInactive() {
        this.removeClass("show");
    }
    setCurrent(location: IPageLocation, model?: any) {
        for (let path in this._elementsById) {
            const value = this.getValueFromModel(path, model);
            this._elementsById[path].setValue(value ?? "");
        }

        this._current = new SitePageCurrent(location, model);
    }
    getCurrent() {
        if (this._current == null)
            return null;

        var model = this._current.getModel();
        for (let path in this._elementsById) {
            const value = this._elementsById[path].getValue();
            this.setValueToModel(path, model, value);
        }

        return this._current;
    }
    private getValueFromModel(path: string, current: any): any {
        const parts = path.split(/\.|\[|\].?/).filter(p => p);

        for (let part of parts) {
            if (current[part] === undefined) {
                return undefined; // Or some default value
            }
            current = current[part];
        }

        return current;
    }
    private setValueToModel(path: string, current: any, value: any): void {
        const parts = path.split(/\.|\[|\].?/).filter(p => p);

        for (let i = 0; i < parts.length - 1; i++) {
            const part = parts[i];

            if (current[part] === undefined) {
                current[part] = {};
            }

            current = current[part];
        }

        const lastPart = parts[parts.length - 1];
        current[lastPart] = value;
    }
}

class SitePagesLocation
    extends QueryElement<HTMLElement> {

    private readonly _sitePages: SitePages;
    private _history: ISitePagesLocationHistory[] = [];
    private _historyObject: IQueryElement;

    constructor(element: HTMLElement, sitePages: SitePages) {
        super(element);

        this._sitePages = sitePages;
        this._historyObject = this.query(".history").first() 
            ?? throwError("Unable to get history element");
        var closeObject = this.query(".close").first()
            ?? throwError("Unable to get history element");

        closeObject.on("click", () => {
            sitePages.hide()
        })
    }

    async setLocation(title: string, href: string, startPage: boolean, model?: any) {

        // Set location
        var parsedLocation = PageMap.parsePageLocation(href);
        if (!PageMap.hasPageMap(parsedLocation.page)) {
            throw `Location ${href} not found!`;
        }

        var instancePage = PageMap.createInstance(parsedLocation.page, this._sitePages.getRepository());
        var pageResult = await PageMap.callMethod(instancePage, parsedLocation.view, {
            id: parsedLocation.id,
            model: model
        });
        await pageResult.execute(this, parsedLocation);

        // Stop when not returned a page
        if (!(pageResult instanceof PageViewResult))
            return;

        // Update history if we returned a page
        let noAddHistory = false;
        if (startPage) {
            this._history = this._history.slice(0, 1);
            if (this._history.length > 0 && this._history[0].href == href)
                noAddHistory = true;
        }

        if (!noAddHistory) {
            this._history.push({
                title: title,
                href: href
            });
        }

        this.redraw();
    }

    getPage(pageName: string) {
        return this._sitePages.getPage(pageName);
    }

    makeActive(page: SitePage, location: IPageLocation, model?: any) {
        return this._sitePages.makeActive(page, location, model);
    }

    refresh() {
        var item = this._history.pop();
        if (item != null)
            this.setLocation(item.title, item.href, false);
    }

    goBack(steps: number) {
        let last: ISitePagesLocationHistory | undefined;

        for(var x = 0; x <= steps; x++)
        {
            var item = this._history.pop();
            if (item != null)
                last = item;
        }

        if (last !== undefined)
            this.setLocation(last.title, last.href, false);
    }

    private redraw() {
        var historyLength = this._history.length - 1;
        var translate = this._sitePages.getTranslate();

        this._historyObject.clear();
        for(var x = 0; x < this._history.length; x++) {
            var history = this._history[x];
            const steps = historyLength - x;
            var element = QueryElement.QueryElement("button")
                .setHtml(Translator.htmlTranslate(history.title, translate))
                .appendTo(this._historyObject)
            element.on("click", () => {
                this.goBack(steps);
            });
            if (steps == 0)
                element.addClass("active");
        }
    }
}