abstract class AbstractSiteElement
    extends QueryElement<HTMLElement> {

    constructor(element: HTMLElement, sitePage: SitePage) {
        super(element);
    }

    getId() {
        return this.getAttr("data-id") ?? null;
    }

    abstract getValue(): any;
    abstract setValue(value: any) : void;
}