namespace Pages {
    export class PrintersPage {
        private readonly _repository: Repository;

        constructor(repository: Repository) {
            this._repository = repository;
        }
        async index() {
            return new PageViewResult("Printers/", {});
        }
        async add() {
            return new PageViewResult("Printers/add/", new Entities.Printer({
                name: "",
                location: "",
                ipAddress: ""
            }));
        }
        async insert({ model }: { model: Entities.Printer }) {

            await this._repository.put(model);

            return new PageActionResult(
                new PageActionBack(new PageActionEvent("printerChanged"))
            );
        }
        async edit({ id }: { id: string }) {
            const user = await this._repository.get<Entities.Printer>(id, Entities.Printer);

            return new PageViewResult("Printers/edit/", 
                user
            );
        }

        async update({ id, model }: { id: string, model: Entities.Printer}) {

            await this._repository.put(model);

            return new PageActionResult(
                new PageActionBack(new PageActionEvent("printerChanged"))
            );
        }

        async delete({ id, model }: {id: string, model: Entities.Printer}) {
            await this._repository.delete(model);
            return new PageActionResult(
                new PageActionBack(new PageActionEvent("printerChanged"))
            );
        }
    }
}